import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import PastReportFooter from "./PastReportFooter";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment/moment";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { isValidDate } from "../../methods";
import DOMPurify from 'dompurify';

function PastReportTable({ error = false, pastReport, updatePatientReport }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const column = [
    {
      Header: t("prac_past_report_table_header_1"),
      accessor: "created_at",
      Cell: ({ value }) => {
        return (
          //   <a className="pg-text" onClick={() => {}}>
          <div className="position-relative">
            {/* {moment(value.value).format("MMM Do, YYYY")} */}
            {isValidDate(value)
              ? format(new Date(value), "MMM do, yyyy", {
                locale: i18n.language === "fr" ? fr : undefined,
              })
              : value}
          </div>
          //   </a>
        );
      },
    },
    {
      Header: t("prac_past_report_table_header_2"),
      accessor: "msq_score",
      Cell: (value) => {
        const data = value;
        return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.value) }} />;
      },
    },
    {
      Header: t("prac_past_report_table_header_3"),
      accessor: "report_id",
      sortable: false,
      filterable: false,
      Cell: (value) => {
        const data = value?.row?.original;
        if (data.genetic_report_name == "Error") {
          return "";
        } else {
          return (
            <button
              className="btn bgc-primary text-light"
              onClick={() =>
                gotoReportPage(
                  data.patient_id,
                  data.report_id,
                  data.pg2_report_url,
                  data.type
                )
              }
            >
              {t("prac_past_report_view_result_txt")}
            </button>
          );
        }
      },
    },
  ];

  const gotoReportPage = (patient_id, reportId, pg2_report_url, type) => {
    if (!_.isEmpty(pg2_report_url)) {
      window.open(pg2_report_url);
    } else {
      if (type === "pg3") {
        updatePatientReport({
          currentPatientId: patient_id,
          currentReportId: reportId,
        });
        navigate("/practice/patient/report");
      } else {
        navigate(`/practice/report/new/${reportId}`, {
          state: { fromPastReport: true },
        });
      }
    }
  };

  const columns = useMemo(() => column, []);
  const data = useMemo(() => pastReport, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="my-4">
      <table
        {...getTableProps()}
        className="pg-table table-bordered past-report-table my-2"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span className="fs-4">
                    {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {error && (
          <tbody>
            <tr className="w-100">
              <td colSpan="5">
                <div className="w-100 text-center">{t("patient_rep_err1")}</div>
              </td>
            </tr>
          </tbody>
        )}
        {pastReport.length < 1 && !error && (
          <tbody>
            <tr>
              <td colSpan="5">
                <div className="w-100 text-center">{t("patient_rep_err2")}</div>
              </td>
            </tr>
          </tbody>
        )}
        {pastReport.length > 0 && !error && (
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <PastReportFooter
        pageSize={pageSize}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
      />
    </div>
  );
}

export default PastReportTable;
