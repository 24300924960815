import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { encode }from 'url-safe-base64'
import ConsentPopup from "../ConsentPopup";

export function ExternalGeneticVendor({patientConsent, setPatientConsent, vendorName}) {
  const { t, i18n } = useTranslation();
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const currentPatientId = useSelector((state) => state.patientReport.currentPatientId);
  const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const userType = useSelector((state) => state.auth.userType);
  const token = useSelector((state) => state.auth.token);
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  const encoded_string = (patientId, currentPractice, currentPracticeID, PG3_BASE_URL) => {
    let plain_text = "patient_id=" + patientId + "&vendor=" + vendorName

    return fetch(
      `${window.location.protocol}//${currentPractice}${PG3_BASE_URL}/get_encrypted_text`,
      {
        headers: {
          "Content-Type": "application/json",
          "Current-Practice": currentPracticeID,
          Authorization: token,
        },
        body: JSON.stringify({"plain_text":  plain_text}),
        method: "POST"
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return data.encrypted_text;
      })
      .catch((error) => {
        console.error("Error fetching encrypted text:", error);
      });
  };

  const handleAcceptConsent = async (e) => {
    setPatientConsent(false)
    const externalUrl = await getExternalUrl(vendorName);
    window.open(externalUrl, "_blank")
  };

  const getExternalUrl = async (vendorName) => {
    let url = ""    
    if (vendorName == "dna_life"){
       url = "https://puregenomics.nordicvms.com/default.aspx?pgref="
    }
    else{
       url = process.env.REACT_APP_DNALABS_URL
    }
    const patient_id = userType == 'Patient'? currentUserId : currentPatientId
    const encryptedString = await encoded_string(patient_id, currentPractice, currentPracticeID, PG3_BASE_URL)
    url = url + encryptedString;

    return url
  }

  return (
    <div>    
      {patientConsent && (
        <ConsentPopup
          showModal={true}
                    content={vendorName == 'dna_life' ? t('patient_snp_nordic_consent_t1') : t('patient_snp_dnalabs_consent_t1')}
                    showClose={true}
                    showButton={true}
                    acceptTerms={() => handleAcceptConsent()}
          handleHide={() => setPatientConsent(false)}
          title={t('patient_snp_dnalabs_title_t1')}
        />
      )}
    </div>
  );
}