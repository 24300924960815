import { useEffect, useRef } from "react";
import { ColorsCircle } from "../../ColorsCircle";
import { t } from "i18next";
import DOMPurify from 'dompurify';

function PatientRecommendationContainer({
  reportData,
  focusAreas,
  onNavigateToViewRecommededScript,
}) {
  return (
    <div>
      <div className="row justify-content-between">
        <div className="col-12 col-md-6 col-xl-4 ">
          <RecommendationCard
            data={reportData?.diet_recommedations || {}}
            focusAreas={focusAreas}
            context="Diet"
            title={t("diet")}
          />
        </div>
        <div className="col-12 col-md-6 col-xl-4">
          <RecommendationCard
            data={reportData?.lifestyle_recommedations || {}}
            focusAreas={focusAreas}
            context="Lifestyle"
            title={t("lifestyle_txt")}
          />
        </div>
        <div className="col-12 col-md-6 col-xl-4">
          <RecommendationCard
            data={reportData?.supplement_recommendations || {}}
            focusAreas={focusAreas}
            context="supplement"
            title={t("supplement_txt")}
            onNavigateToViewRecommededScript={onNavigateToViewRecommededScript}
          />
        </div>
      </div>
    </div>
  );
}

export default PatientRecommendationContainer;

const RecommendationCard = ({
  data,
  focusAreas,
  context,
  onNavigateToViewRecommededScript,
  title,
}) => {
  const containerRef = useRef(null);
  const shadowRef = useRef(null);
  const handleScroll = () => {
    const container = containerRef.current;
    const shadow = shadowRef.current;
    if (container.scrollHeight > container.clientHeight) {
      const isScrolledToBottom =
        container.scrollHeight - container.scrollTop === container.clientHeight;
      shadow.style.opacity = isScrolledToBottom ? 0 : 1;
    } else {
      shadow.style.opacity = 0;
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    handleScroll(); // Check scroll position initially
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className="pg4-card rounded p-3 py-3 position-relative">
        <div className="d-flex justify-content-between">
          <h3 className="pg4-text__subheading text-capitalize">{title}</h3>
        </div>
        <div className="d-flex flex-wrap">
          {focusAreas?.map((fa, idx) => (
            <span className={`pill pill-${fa?.key?.toLowerCase()}`} key={idx}>
              {fa.value}
            </span>
          ))}
        </div>
        <div className="border-bottom border-top text-center mt-3" />
        <b className="pg4-text text_primary">{t("recommendations")}</b>
        <div ref={containerRef} className="r-item__container">
          {data?.recommendations?.map((item) => (
            <div className="rounded p-1 p-lg-2 bg_tertiary my-2 user-select-none d-flex justify-content-between align-items-center">
              <div>
                <div>
                  <ColorsCircle
                    focusAreas={focusAreas}
                    data={item.associated_focus_areas_key}
                  />
                </div>
                {context === "supplement" ? (
                  <div>
                    <b
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item?.supplement_name),
                      }}
                    />
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.desc) }} />
                  </div>
                ) : (
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      WebkitLineClamp: "2",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                    }}
                    className="user-select-none"
                  >
                    {item.recommendation}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div ref={shadowRef} className="scroll-shadow__patient"></div>
        {context === "supplement" && (
          <button
            onClick={onNavigateToViewRecommededScript}
            className="pg4-btn position-relative pg4-btn_outline  w-100 text-secondary border-secondary"
          >
            {t("view_recommendation")}
          </button>
        )}
        <div className="pg4-text__subheading my-2 text_primary fw-bold">
          {t("report_diet_recom_subheader_1")}
        </div>
        <textarea
          readOnly={true}
          disabled={true}
          defaultValue={data?.notes}
          className="w-100 patient_notes patient_notes_dl"
          id=""
          rows={5}
        />
      </div>
    </div>
  );
};
