import React from "react";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import L_Multiline from "../LoadingEffectComponent/L_Multiline";
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';

function ConsentPopup({
  showModal,
  onCancel,
  showClose,
  content,
  showButton,
  handleHide,
  acceptTerms,
  title
}) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      show={showModal}
      onHide={handleHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName="border-radius"
      backdrop="static"
      className="edit-patient-modal"
      style={{ padding: "25px" }}
    >
      <Modal.Header closeButton={showClose} style={{ borderBottom: "none" }}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="pg-text">
          <div className="pg-heading my-3 mx-4">
            {title}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "450px", overflowY: "scroll" }}>
        {content ? (
          <div className="my-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
        ) : (
          <div className="m-5">
            <L_Multiline />
          </div>
        )}
        {showButton && (
          <div className="text-center">

            <button
              type="button"
              className="btn pd-btn agree_btn_style"
              onClick={() => acceptTerms()}
            >
              {t('pract_account_agree_btn')}
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ConsentPopup;
