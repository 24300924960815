import React from "react";
import ActionImage from "../../ActionImage";
import DOMPurify from 'dompurify';

const LabFindings = ({ labResultFindings, userType, t }) => {
	const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
	return (
		<div className="">
			<hr className="my-5" />
			<div className="pg-heading mt-5" style={patientTextColor}>{t('gene_analysis_th_4')}</div>
			<div className="pg-text my-2">
				{labResultFindings?.length < 1 ? (
					<div className="pg-text">{t('gene_analysis_td_6')}</div>
				) : (
					labResultFindings?.map((labResultFinding, i) => {
						return (
							<div key={i}>
								<div className={"my-3 " + (labResultFinding.name ? 'pg-text__bold' : 'pg-text')} style={{ whiteSpace: 'pre-line'}}>{labResultFinding.title}</div>
								<div
									dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(labResultFinding.ranges) }}
									className="pg-text"
								/>
								{labResultFinding.patient_result && (
									<div className="pg-link">
										{t('gene_analysis_td_7')} : {labResultFinding.patient_result}
									</div>
								)}
							</div>
						);
					})
				)}
				{labResultFindings?.length > 0 && (
					<div className="pg-text">
						{/* Both assays are useful as vitamin A status may be low and{" "}
          <br className="d-none d-lg-inline-block" />
          serum beta-carotene levels may be elevated due to poor{" "}
          <br className="d-none d-lg-inline-block" />
          conversion to retinol. */}
					</div>
				)}
			</div>
		</div>
	);
};


export default LabFindings;
