import { applyMiddleware, createStore } from "redux";
import storage from "redux-persist/es/storage";
import { persistReducer, persistStore } from "redux-persist";
import { apiMiddleware } from "redux-api-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import toast from 'react-hot-toast';

// import thunk from "redux-thunk";
import reduxMiddleware from "react-block-ui/lib/reduxMiddleware";
import { routerMiddleware } from "connected-react-router";
import interceptor from "redux-api-middleware-interceptor";
import pgReducer from "./reducers";
import { handleLogout } from "../methods/auth_methods";
import _ from "lodash";
import DOMPurify from 'dompurify';
// import

const storeMain = (history) => {
  const reducer = persistReducer(
    {
      timeout: null,
      key: process.env.REACT_APP_STORE_KEY,
      whitelist: [
        "auth",
        "patientReport",
        "trait",
        "genetics",
        "labUpload",
        "snpUpload"
      ],
      storage: storage,
    },
    pgReducer(history)
  );

  const store = createStore(
    reducer,
    composeWithDevTools(
      applyMiddleware(
        interceptor({
          headers: (origHeaders, state) => {
            const headers = Object.assign({}, origHeaders);
            if (state.auth.isAuth) {
              // headers['Authorization'] = `Bearer ${state.auth.token}`;
              headers['Current-Domain'] = state.auth.currentPractice;
              headers['Current-Type'] = state.auth.userType;
            }
            return headers;
          },
          onRequestError: (state, response) => {
            if (response.status_code === 401) {
              handleLogout(state, response);
            } else {
              if (response.html)
                toast(
                  <div className="d-flex justify-content-between text-justify">
                    <i className="my-auto mx-2 fas fa-times-circle text-danger fs-3 "></i>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(response.html) }} />
                  </div>, { duration: 8000, style: { fontSize: 18 } });
              else if (_.isString(response.error)) {
                toast.error(response.error)
              }
              else if (_.isArray(response.errors)) {
                if (_.isString(response?.errors[0]?.detail))
                  toast.error(response?.errors[0]?.detail)
                else
                  toast.error("Something went wrong..")
              }
              else {
                console.error('global error-->', response)
                toast.error("Something went wrong..")
              }
            }

          },
        }),
        apiMiddleware,
        // thunk,
        routerMiddleware(history),
        reduxMiddleware
      )
    )
  );

  persistStore(store);
  return store;
};

export default storeMain;
