export const CAProviderFAQTitle = `PureInsight<sup>™</sup> Healthcare Provider FAQs`;
export const FRProviderFAQTitle = "FAQ sur les professionnels de santé PureInsight<sup>🅪</sup>";
export const CAPatientFAQTitle = "PureInsight<sup>™</sup> Patient FAQs";
export const FRPatientFAQTitle = "FAQ patients PureInsight<sup>🅪</sup>";

export const CAProviderFAQData = [
  {
    section: "PureInsight<sup>™</sup> Usage and Registration",
    faqs: [
      {
        question: "What is PureInsight<sup>™</sup>?",
        answer: `
            PureInsight<sup>&trade;</sup> is our new, complementary platform that helps delivers simple, personalized health reports for your patients that easily incorporate into your existing clinical environment. This platform simplifies the data collection process, prioritizes patient needs and assists in developing a holistic, scientifically supported plan of care that focuses on diet, exercise, lifestyle and supplement recommendations. Unlike PureGenomics<sup>&reg;</sup>, our PureInsight<sup>&trade;</sup> platform allows healthcare providers utilize patient questionnaires and validated tools to provide a plan of care. With PureInsight<sup>&trade;</sup>, genetic data and lab test data is no longer required but remain optional for your patient to upload.
          `,
      },
      {
        question: "Why is PureGenomics<sup>®</sup> changing to PureInsight<sup>™</sup>?",
        answer: `
            We want our services to align with your needs and our go-forward strategy to support and empower you in designing evidence- based, personalized and a scientifically supported plan of care focused on diet, exercise, lifestyle, and supplement recommendations for your patients.
          `,
      },
      {
        question: "Will the rebrand introduce a new logo?",
        answer: `
            Yes, the new logo is shown throughout the website – PureInsight<sup>&trade;</sup> Powered by PureGenomics<sup>&reg;</sup>
          `,
      },
      {
        question: "What is changing for me?",
        answer: `
              <p>The look and feel of our website will change slightly. Individual healthcare provider pages will remain the same with updated descriptions to help emphasize what this platform delivers and the tools available when you sign in to your PureInsight<sup>&trade;</sup> account.  The PureInsight platform introduces new features such as a Multi-Symptom Questionnaire that simplifies and streamlines patient data collection, clinically validated questionnaires such as GAD-7, PHQ-9, PSS-4 and optional genetic reports and polygenic soring that can help provide insights to support your plan of care.</p>
              <p>Rest assured that you will continue to receive the exceptional services and features that you have grown accustomed to. This transition will be smooth and effortless, allowing us to assist you in utilizing our services with even greater efficiency. </p>
          `,
      },
      {
        question: "I have a PureGenomics<sup>®</sup> account. Do I need to create a new one to access PureInsight<sup>™</sup>?",
        answer: `
            No, your credentials from your PureGenomics<sup>&reg;</sup> account can be used to log in and access the PureInsight<sup>&trade;</sup> platform.
          `,
      },
      {
        question: "I did not have a PureGenomics<sup>®</sup> account. How do I get access to PureInsight<sup>™</sup>?",
        answer: `
            If you don’t have access, you can register <a href="/hcp/registration">here</a>. If you are a Pure Encapsulations<sup>&reg;</sup> account holder, you can register using your Pure account credentials.
          `,
      },
      {
        question: "Is the https://www.puregenomics.ca/  web address staying the same?",
        answer: `
            No, the new web address is <a href="https://pureinsightpro.ca">pureinsightpro.ca</a>
          `,
      },
      {
        question: "Is there a specific transition plan I need to be aware of?",
        answer: `
            No, we will gradually transition to the new brand. Access to your healthcare provider account is not impacted and you can use new features and tools 24/7.
          `,
      },
      {
        question: "Where can I send any follow-up questions I might have?",
        answer: `
            For any additional questions about the rebranding of PureGenomics to PureInsight<sup>&trade;</sup>,
              please click on <a href="/contact-us">Contact Us</a> and submit your inquiry.
          `,
      },
      {
        question: "What is the cost to use PureInsight<sup>™</sup>?",
        answer: `
            PureInsight<sup>&trade;</sup> is complimentary to use! We believe in helping all achieve  health and wellness goals, that's why you can benefit from all that PureInsight<sup>&trade;</sup> has to offer at no cost.
          `,
      },
      {
        question: "How can I sign up for a PureInsight<sup>™</sup> account?",
        answer: `
            If you have your credentials from PureGenomics<sup>&reg;</sup>, use them to sign in to PureInsight<sup>&trade;</sup>. If you did not have a PureGenomics<sup>&reg;</sup> account, sign up for PureInsight<sup>&trade;</sup>by clicking this link: <a href="https://pureinsight.pureencapsulationspro.com">pureinsight.pureencapsulationspro.com</a>.
          `,
      },
      {
        question: "Upon attempting to register, I received an email that said more information is needed from me to complete my registration. What does this mean?",
        answer: `
            This means that we need to verify that you are a healthcare provider to complete the account setup. Please email us a copy of your credentials to complete your registration.
          `,
      },
      {
        question: "How does a patient create a PureInsight<sup>™</sup> account?",
        answer: `
              <p>The healthcare provider must first choose “Add New Patient” on their dashboard. This will trigger the system to send the patient a registration email. </p>
              <p>The patient must click on the link in the registration email to activate their account. The patient is prompted to create a password and log into their patient portal. </p>
          `,
      },
      {
        question: "Can I register my patients for PureInsight<sup>™</sup> if they have not received their genetic test results yet?",
        answer: `
              Yes, you can add patients and they can activate their account even if they have not yet received their genetic test results from 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>. With PureInsight<sup>&trade;</sup>, genetic data is not required but remains as an option for your patients.
          `,
      },
      {
        question: "While attempting to add a new patient, I received an error message that the email address is already in use. How do I proceed?",
        answer: `
              <p>You will need to obtain an alternative email address from the patient as the email address is a unique identifier and can only be used one time. Since that email has been used previously, the patient will need to provide another email address for the account.</p>
              <p>If this patient has a PureInsight<sup>&trade;</sup> account with a different provider using the email address, your patient can also contact customer support to request their account be transferred to your dashboard by emailing us.</p>
          `,
      },
      {
        question: "My patient wants to set their family members up with PureInsight<sup>™</sup> accounts. How do they do this?",
        answer: `
              <p>Family members will need to be invited by you or another participating provider to set up their own account.  Patients must be eighteen (18) years of age or older.</p>
              <p>Each patient must use a different email address to create an account.</p>
          `,
      },
      {
        question: "What if my patients are not tech-savvy?",
        answer: `
              Many tech questions can be resolved easily by your staff, like helping patients log into their accounts and understanding how to download the text file. If the tech problem is not easily resolved, please have your patients <a href="/contact-us">Contact Us </a> for assistance.
          `,
      },
    ],
  },
  {
    section: "Practice Management",
    faqs: [
      {
        question: "How do I add staff members to my practice?",
        answer: `
              After you are logged in, click on your name in the top right corner of the page to access the Staff Management page. Then click the “Add New Staff Member” button to open the form for adding a new staff member. Complete the required fields, assign the desired permissions, and click “Save” to finish. Your new staff member will receive an email to set their password and activate their account.
          `,
      },
      {
        question: "What can staff members do?",
        answer: `
              Staff members can add patients, view the patient list, send patient reminders, and view patient reports. Depending on the optional permissions selected by the practice owner, staff members can also upload patient genetic files and lab files, view recommendations, and view the catalogue and substitutions. Staff members are not permitted to create recommendations, edit recommendations, or perform practice management tasks such as adding other staff members.
          `,
      },
      {
        question: "How do I manage my staff members’ permissions?",
        answer: `
              Optional permissions are user specific and can be configured from the Staff Management page. After you are logged in, click on your name in the top right corner of the page to access the Staff Management page. Then select a staff member from the list and click “Manage” to open the account details for that staff member. Assign the desired permissions and click “Update” to finish.
          `,
      },
      {
        question: "How do I remove a staff member from my practice?",
        answer: `
              After you are logged in, click on your name in the top right corner of the page to access the Staff Management page. Then select a staff member from the list and click “Archive Staff Member”. Their account status will be updated to Archived and they will no longer be able to access their account.
          `,
      },
      {
        question: "How do I update my personal information?",
        answer: `
              All changes can be made from your account dashboard by clicking <b>My Account,</b> found under where your name is listed at the top of the page.
          `,
      },
      {
        question: "I have a patient I am no longer seeing. How do I delete this patient off my dashboard?",
        answer: `
              Patients cannot be permanently removed from your dashboard. If you have a patient who is no longer active within your practice, you can archive them from within your dashboard. To archive, click Edit Patient in their patient row.
          `,
      },
    ],
  },
  {
    section: "Personalization and Questionnaires",
    faqs: [
      {
        question: "How does PureInsight<sup>™</sup> deepen personalization?",
        answer: `
              PureInsight<sup>&trade;</sup> allows you to collect a variety of your patient’s clinically relevant information from questionnaires, DNA, and blood work to help you clearly identify priority areas and help your patients achieve their health and wellness goals.  The DNA data provides nutrigenomic insights to better understand how your patients’ genetics may influence their health which you can use to make highly personalized supplements and lifestyle recommendations based on both the patients’ genetics and questionnaire results. A healthcare provider cannot diagnose or prescribe medication using the PureInsight<sup>&trade;</sup> services including the nutrigenomics report.
          `,
      },
      {
        question: "What is the Multi-Symptom Questionnaire (MSQ)?",
        answer: `
              The MSQ is for more personalized lifestyle and dietary recommendations. This is a, short and engaging questionnaire that takes 5-7 minutes for patients to complete. The questionnaire provides you with more specific information about your patient to use in combination with their genetic data for a comprehensive personalized plan. While genetic data can help provide a comprehensive personalized plan, it is no longer required and your patient can chose to complete the MSQ only. Responses to the MSQ allow for more refinement of the recommendations. Examples include a patient's habits (such as caffeine consumption), preferences, goals, and others.
          `,
      },
      {
        question: "Can my patient complete the Multi-Symptom Questionnaire if they don’t yet have their genetic raw data file?",
        answer: `
              Yes, a patient can complete the questionnaire prior to receiving their genetic raw data file. While genetic data remains an option, it is no longer required. You can send an invite to the patient from your patient dashboard. You will be able to view the questionnaire responses from your patient dashboard. To view the responses, click the arrow across from your patient’s name and click View Responses under Questionnaire.
          `,
      },
      {
        question: "Can a patient edit their responses?",
        answer: `
              Patients will have the ability to edit their responses before submitting their final answers. They will only have one opportunity to make any necessary changes. If you or they need to edit any answers beyond this, a new questionnaire will need to be completed (which can be requested from your dashboard).
          `,
      },
      {
        question: "Can my patient request a new Multi-Symptom Questionnaire after several months of care?",
        answer: `
              Yes. From the patient dashboard, your patient can click the arrow across from the patient’s name and click Request New Questionnaire.
          `,
      },
      {
        question: "What is the basis of using the additional questionnaires for patients? - Generalized Anxiety Disorder Questionnaire (GAD-7), Patient Health Questionnaire (PHQ-9), and Perceived Stress Scale Questionnaire (PSS-4)?",
        answer: `
              <p>Based on how the responses to the Multi-Symptom Questionnaire, you may request your patient complete certain additional questionnaires. These additional assessment instruments can help you gain a deeper understanding of your patient’s health and wellness status.</p>
              <p>These additional questionnaires do not impact the patient’s Multi-Symptom Questionnaire score but helps deepen the understanding of the patient’s health and wellness needs.</p>
          `,
      },
    ],
  },
  {
    section: "Genetic Testing, Labs and Upload",
    faqs: [
      {
        question: "What genetic testing labs are compatible with PureInsight<sup>™</sup>?",
        answer: `
              PureInsight<sup>&trade;</sup> <b>only</b>  interprets genetic data from DNALabs, Nordic Labs, 23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup>.
          `,
      },
      {
        question: "As a healthcare provider, how do I upload my own  Nordic Labs, 23andMe<sup>®</sup> or Ancestry<sup>®</sup>  test results to PureInsight<sup>™</sup>?",
        answer: `
              When you receive your Nordic Labs, 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> test results, 
              log into your PureInsight<sup>&trade;</sup> account. 
              To upload and process your own genetic data, please do the following:
            <ol type="1" class="lh-lg">
              <li>Click
                <b>
                  <i> Add New Patient.</i>
                </b>
              </li>
              <li>
                Check the
                <b>
                  <i> Add Myself as a Patient.</i>
                </b>
              </li>
              <li>Enter any missing information.</li>
              <li>If you would like to take the Questionnaire, check the box.</li>
              <li>If you have labs to upload, check the box.</li>
              <li>
                Click
                <b>
                  <i> Done. </i>
                </b>
                The platform will take you to your patient list.
              </li>
              <li>
                Under
                <b>
                  <i> Genetics </i>
                </b>
                Click
                <b>
                  <i> Start Upload.</i>
                </b>
              </li>
            </ol>
          `,
      },
      {
        question: "I noticed that Nordic Labs, 23andMe<sup>®</sup> and Ancestry<sup>®</sup> offer different DNA kits you can purchase. Is there a particular DNA kit that patients should buy if they are interested in using your platform?",
        answer: `
              Any DNA kit sold by either  Nordic Labs, 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> 
              is compatible with PureInsight<sup>&trade;</sup>.
          `,
      },
      {
        question: "Can I order a Nordic Labs, 23andMe<sup>®</sup> or Ancestry<sup>®</sup> test kit through PureInsight<sup>™</sup>?",
        answer: `
              No. You must order the DNA kit directly through 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> or other authorized
              retailers. Please note that PureInsight<sup>&trade;</sup> is not
              affiliated with or endorsed by 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>.
          `,
      },
      {
        question: "How do patients download their raw data from 23andMe<sup>®</sup> or Ancestry<sup>®</sup>, and upload this data to PureInsight<sup>™</sup>?",
        answer: `
              <div>
                <span className="text-decoration-underline fw-bold">
                  Through 23andMe<sup>&reg;</sup>
                </span>
                : Once logged into their PureInsight<sup>&trade;</sup> account, the patient will need to click the
                <a
                  href="https://you.23andme.com/tools/data/download/"
                  data_external="true"
                  target="_blank"
                >
                  23andMe <sup>&reg;</sup>
                </a> 
                link to log into their account.
              </div>
              <br />
              <div>
                The patient will scroll down to the bottom of the page, check a
                box acknowledging they are downloading from a secure server, and
                then click submit request.
              </div>
              <br />
              <div>
                23andMe<sup>&reg;</sup> requires a 2-step authentication process.
                The patient will receive an email from 23andMe<sup>&reg;</sup> to
                let them know their raw data download is ready. The call to action
                at the bottom of 23andMe<sup>&reg;</sup>’s email takes the patient
                back to their 23andMe<sup>&reg;</sup> account where they can now
                download their raw data file and save it to their computer.
              </div>
              <br />
              <div>
                Lastly, the patient goes back into their PureInsight
                <sup>&trade;</sup> patient dashboard and clicks <b>Upload Your Data,</b>
                selects their file from the saved location, and clicks
                <b>Start Upload</b>.
              </div>
              <br />
              <div>
                Once their data is uploaded, the patient will be able to see their
                patient report. The patient’s report does not include supplement
                or lab recommendations. You will also be notified that their
                report is ready to view from your practitioner dashboard.
              </div>
              <br />
              <div>
                <span className="text-decoration-underline fw-bold">
                  Through Ancestry<sup>&reg;</sup>
                </span>
                : Once logged in to their PureInsight<sup>&trade;</sup> 
                account, the patient will need to click the
                <a
                  target="_blank"
                  href="https://www.ancestry.ca/account/signin/?returnUrl=https%3A%2F%2Fwww.ancestry.ca%2Faccount%2Fdata%2F"
                  data_external="true"
                >Ancestry.ca</a>
                link to log into their account. Directions are provided on this
                page for the patient to download their genetic raw data file.
              </div>
              <br />
              <div>
                Ancestry.ca requires a 2-step authentication process. The patient
                will receive an email from the testing company to let them know
                their raw data download is ready. The call to action
                <b>Confirm Data Download</b> at the bottom of the company’s email
                takes the patient back to their
                Ancestry.ca account where they
                can now download their raw data file and save it to their
                computer.
              </div>
              <br />
              <div>
                Lastly, the patient goes back into their PureInsight<sup>&trade;</sup> 
                patient dashboard and clicks <b>Upload Your Data,</b>
                selects their file from the saved location, and clicks
                <b>Start Upload</b>.
              </div>
              <br />
              <div>
                Once their data is uploaded, the patient will be able to see their
                patient report. The patient’s report does not include supplement
                or lab recommendations. You will also be notified that their
                report is ready to view from your practitioner dashboard.
              </div>
              <br />
          `,
      },
      {
        question: "Can I upload data for my patients?",
        answer: `
              Yes, you can upload the patient’s data for them. They need to
              provide you their genetic raw data file or access to their testing
              kit account.
              <br />
              <div>
              To upload data for your patient, you first need to create the
              patient’s PureInsight<sup>&trade;</sup> account. Click
              <b>
                <i> Add New Patient. </i>
              </b>
              On the next page, you will be asked to enter the patient’s name,
              email address, date of birth and gender. In the Genetics section, select the
              <b>
                <i> Practitioner will upload, </i> option{" "}
              </b>
              which will give you the ability to upload their data file.
              </div>
              <br />
              <div>
              If the patient already has a PureInsight<sup>&trade;</sup> account,
              you will go to
              <b> View Patients</b> and then click on the right arrow found across
              the row of the patient’s name. Then click on <b>Start Upload</b> and
              choose their genetic data file.
              </div>
          `,
      },
      {
        question: "I received an error message when I tried to upload my patient’s genomic data. How do I fix this?",
        answer: `
              There are three reasons an error may occur while uploading your
              patient’s genomic data.
            <ol type="1">
              <li>
                The data is from a non-compatible testing company. PureInsight
                <sup>&trade;</sup> is only compatible with 23andMe<sup>&reg;</sup>
                or Ancestry
                <sup>&reg;</sup> test results.
              </li>
              <li>
                Your patient provided results that is in a format other than a
                text or a zip file (like a PDF). Please ensure the file you are
                uploading is in a .txt or .zip file format. This is also the
                common download format from 23andMe<sup>&reg;</sup> or Ancestry
                <sup>&reg;</sup>.
              </li>
              <li>
                The patient’s data is unreadable. Data may become corrupt and is
                unintelligible by our system. Please ask your patient to
                re-download the file from the testing website and send it to you
                again. When their device asks them if they wish to open or save the file, they will need to select save as opening the file can lead to corruption.
              </li>
            </ol>
          `,
      },
      {
        question: "How long after patients complete the 23andMe<sup>®</sup> or Ancestry<sup>®</sup> genetic test will they get their results?",
        answer: `
              Both companies state that a period of 6-8 weeks can be expected. Once the patient receives a notification that the results are ready, the patient is ready to login to the testing company’s website and download their genetic raw data file
          `,
      },
      {
        question: "How does the Lab Upload capability work?",
        answer: `
              The lab upload capability easily displays nutrient status for ease in decision-making.This optional feature allows a practitioner to upload results from lab vendors such as labcorp and Quest. You can upload the entire PDF or enter the results manually. An AI-driven engine extracts the data that is relevant to the traits in PureInsight<sup>&trade;</sup>. This is an example of phenotype being displayed along with genotype.
          `,
      },
      {
        question: "How do I enter a lab result?",
        answer: `
              From your patient list click the arrow under Lab Results click Start Upload. On the Lab Results Entry page enter the vendor name and click on enter labs and follow the directions and example. Add the name of the Lab test, the results, units and the reference values. Once you have entered all the relevant labs you can save the results.
          `,
      },
      {
        question: "What is the likelihood of a false positive?",
        answer: `
              The likelihood of a false positive is information you would need to
              obtain from the genetic service provider you worked with (23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>).
          `,
      },
      {
        question: "My patients can only view the raw genetic data from 23andMe<sup>®</sup> but not download it, what should I tell them?",
        answer: `
              Unfortunately, we do not have control over the 23andme<sup>&reg;</sup> system. Nevertheless, we maintain ongoing communication with them, and at present, they do not have a specific timeframe for resolving the issue. I suggest advising your patients to reach out to 23andMe<sup>&reg;</sup> for information on alternative methods to obtain their genetic file so they can upload it in PureInsight<sup>&trade;</sup> and get their patient report. Alternatively, our system is also compatible with genetic data from Ancestry<sup>&reg;</sup>, another testing service, which your patients may want to consider getting their genetic data from.
          `,
      },
    ],
  },
  {
    section: "Report",
    faqs: [
      {
        question: "What does a PureInsight<sup>™</sup> report look like?",
        answer: `
              PureInsight<sup>&trade;</sup>  provides an interactive report that
              allows practitioners and patients to explore their results by trait
              category. Practitioners also have access to a printable summary
              report that consolidates all the results into one place. Both
              reports are visible to the healthcare provider.
          `,
      },
      {
        question: "How does a patient generate their PureInsight<sup>™</sup> report?",
        answer: `
              <p>For a PureInsight<sup>&trade;</sup> report to be generated, the
                patient’s genetic raw data file must be uploaded into PureInsight<sup>&trade;</sup>.</p>
              <p>
                Once logged into their PureInsight<sup>&trade;</sup> patient account, they will be given two options: log into their account on their testing company’s website to download their genetic raw data file or, if they already have their data file downloaded, simply upload their data. When the file is downloaded and saved to their device, they are ready to upload their data into PureInsight<sup>&trade;</sup> and generate their report.
              </p>
          `,
      },
      {
        question: "How long will it take for PureInsight<sup>™</sup> to interpret my patients' data?",
        answer: `
              Once the genetic data file is uploaded,  using your independent discretion, you will have the opportunity to review the genetic data and recommendations and then release the PureInsight<sup>™</sup> patient report and your recommendations for the patient’s viewing. The patient is encouraged to schedule a consultation with you to review their PureInsight<sup>™</sup> patient report.
          `,
      },
      {
        question: "Where do I find my patients' lab results in the PureInsight<sup>™</sup> report?",
        answer: `
              The lab results can be found in the Assessment Recommendations
              column of both the HTML and printable PDF report.
              <br />
              The results can also be viewed from the patient dashboard by
              clicking the arrow at the end of the patient row and click
              <b>
                <i> View Results.</i>
              </b>
          `,
      },
      {
        question: "This report looks different from what I am used to. Did you update to a newer version?",
        answer: `
              Yes. We have evolved our reports to reflect the current scientific
              knowledge. We offer tutorial videos that highlight the new changes
              to the website functionality and the reports. 
          `,
      },
      {
        question: "What is the difference between the report I share with my patients and the full DNA report?",
        answer: `
              The report you share focuses on specific areas of a patient’s health and wellness status by allowing you to provide personalized recommendations. The full DNA report provides details about your patient’s analyzed genetic traits.
          `,
      },
      {
        question: "How do I save reports to my computer?",
        answer: `
              To save the report to your computer, click on
              <b> View and Print Practitioner Report</b> and/or
              <b> View and Print Patient Report</b> at the top of the page. You
              will see a download button on the next screen.
              <br />
              When viewing Past Reports, you may not see the View and Print Report button. Instead, you may see a Print button at the top of the page, please click that to save the report. From the printer options, select Print to PDF. This will save the report to your computer.
          `,
      },
      {
        question: "Can I edit a personal recommendation that was already shared with a patient?",
        answer: `
              No, but a new recommendation can be created anytime the patient submits new data or completes a questionnaire.
          `,
      },
      {
        question: "Can reports be generated without supplement recommendations?",
        answer: `
              Patient reports are generated without supplement recommendations until the provider edits and sends the recommendations. Once those recommendations are saved and sent to the patient, they will then be shown on the patient report.
          `,
      },
    ],
  },
  {
    section: "Supplement Recommendations",
    faqs: [
      {
        question: "If blood levels or other lab values do not show a low nutrient level, but the PureInsight<sup>™</sup> report recommends a supplement, what should I do?",
        answer: `
              A SNP alone cannot determine an increased nutrient requirement. However, the presence of a SNP helps identify whether a patient is likely to require additional amounts of a specific form of a nutrient. Clinical assessment is necessary in making final decisions regarding the need for supplementation.
          `,
      },
      {
        question: "How does PureInsight<sup>™</sup> help increase efficiency with supplement recommendations?",
        answer: `
              It decreases time spent creating supplement recommendations, which was a previous pain point per practitioner feedback. Additionally, the supplement dosage and frequency are auto-filled from the product label.
          `,
      },
      {
        question: "Is there a way to print out the supplement recommendations with edits?",
        answer: `
              Once you edit and send the patient supplement recommendations, it will appear in the patient report, and you can print either the entire patient report or just the page containing the recommendations. These recommendations can also be seen by the patient. Also, you have the ability to add any supplement to the list of recommendations by simply pressing the '+ ADD PRODUCT RECOMMENDATION ' button and a free text box will open.
          `,
      },
      {
        question: "Are supplement recommendations and dosage suggestions provided in the report?",
        answer: `
              <p>
              The healthcare provider report includes Pure Encapsulations<sup>&reg;</sup> supplement recommendations, 
              along with suggested dosages.
              </p>
  
              <p>
              PureInsight<sup>&trade;</sup> prioritizes a list of supplement suggestions based on traits where Consider Action is suggested. The supplement recommendation feature enhances customization by auto-populating recommended doses, quantities, and instructions that you can edit. The platform provides the ability to send supplement recommendations to the patient and easily track if they have been reviewed.
              </p>
  
              <p>
              It is up to the health care provider to decide which supplement recommendations will be visible within the patient report. You are only able to edit the supplement recommendations through the interactive report. Edits made will be reflected in the printable summary report. Once your supplement recommendation list is ready for the patient to view, you can push the recommendations through so the patient can see them within their reports.
              </p>
          `,
      },
      {
        question: "Do I need to dispense all recommended supplements for a given trait category?",
        answer: `
              No. For several traits, multiple supplements are suggested. However, the patient may not require all of them. The selection can be fine-tuned by assessing nutrient levels or other types of testing you would normally include in patient evaluations.
          `,
      },
      {
        question: "How can my patients order the recommended supplements?",
        answer: `
              Healthcare providers who have a Pure Encapsulations Pro account can order products to keep on hand for patient purchases, or can direct their patients to visit 
              <a href="https://www.pureencapsulations.ca/en/where-buy" target="_blank">https://www.pureencapsulations.ca/en/where-buy</a>
              . 
          `,
      },
    ],
  },
  {
    section: "Nutrigenomics and Traits",
    faqs: [
      {
        question: "What are traits?",
        answer: `
              Traits are specific characteristics like eye color, blood type, or the ability to tolerate lactose that are based on an individual's unique genetic variations (SNPs), as well as environmental factors.
          `,
      },
      {
        question: "What are Single Nucleotide Polymorphisms (SNPs)?",
        answer: `
              SNPs (single nucleotide polymorphisms) occur normally in a person's DNA, and each SNP represents a genetic variation within a single DNA building block in a gene sequence, called a nucleotide. While most SNPs have no effect on a person's health or development, some can help inform lifestyle choices and health and wellness outcomes.
          `,
      },
      {
        question: "Which trait categories are supported by PureInsight<sup>™</sup>?",
        answer: `
              PureInsight<sup>&trade;</sup> reports offer insights into the
              following trait categories:
            <ul>
              <li>Vitamins, minerals & omega-3s</li>
              <li>Detoxification</li>
              <li>Glucose metabolism</li>
              <li>Metabolic & weight management</li>
              <li>Cognitive health & memory</li>
              <li>Immune health</li>
              <li>Energy & fitness</li>
              <li>Cardiovascular health</li>
              <li>Gastrointestinal health</li>
            </ul>
          `,
      },
      {
        question: "Which testing companies’ genetic raw data file provides the most SNPs on your PureInsight<sup>™</sup> report?",
        answer: `
              <div className="pgs-text">
                23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup> use a
                “genotyping chip” to detect patients’ DNA data. The reference SNP
                cluster IDs, also known as rs numbers, that are included on the raw
                data is dependent on which chip was used. These companies commonly
                update their chip versions for a variety of reasons, which can
                impact the raw data available for further analysis. Below is a
                chart that outlines the differences in the most common chip
                versions used in PureInsight<sup>&trade;</sup>. (The “X” indicates
                that the SNP is included in that chip version).
              </div>
              <div className="overflow-x-scroll overflow-x-lg-hidden">
                <table className="pg-table my-3 text-center fw-bold">
                  <thead>
                    <tr>
                      <td rowSpan="2" className="text-start">
                        SNP Tested
                      </td>
                      <td colSpan="2">23andMe</td>
                      <td>Ancestry</td>
                    </tr>
                    <tr>
                      <td>Version 4</td>
                      <td>Version 5</td>
                      <td>Version 2</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-start">EDN1 (rs5370)</td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td></td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
  
                    <tr>
                      <td className="text-start">GATA3 (rs4143094</td>
                      <td></td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
  
                    <tr>
                      <td className="text-start">AOC1 (rs10156191)</td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td></td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
  
                    <tr>
                      <td className="text-start">BCMO1 (rs12934922)</td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td></td>
                    </tr>
  
                    <tr>
                      <td className="text-start">TCN2 (rs1801198)</td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td></td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
  
                    <tr>
                      <td className="text-start">SLC23A1 (rs33972313)</td>
                      <td></td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td></td>
                    </tr>
  
                    <tr>
                      <td className="text-start">DHCR7 (rs12785878)</td>
                      <td></td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
  
                    <tr>
                      <td className="text-start">CYP2R1 (rs10741657)</td>
                      <td></td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
  
                    <tr>
                      <td className="text-start">GPx1P1 (rs1050450)</td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
  
                    <tr>
                      <td className="text-start">DRD2 (rs6277)</td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
          `,
      },
      {
        question: "What are lab markers that coincide with the PureInsight<sup>™</sup> traits?",
        answer: `
              The following tests can be extracted from the labcorp and Quest reports that correspond to the PureInsight<sup>&trade;</sup> traits or lab results can be manually entered from other vendors.
  
              <div className="overflow-x-scroll overflow-x-lg-hidden">
                <table className="pg-table my-3">
                  <thead>
                    <tr>
                      <td>
                        <b>Vitamins, Mineral & Omega-3 Profile</b>
                      </td>
                      <td>
                        <b>Glucose Metabolism</b>
                      </td>
                      <td>
                        <b>Weight Management</b>
                      </td>
                      <td>
                        <b>Immune Health</b>
                      </td>
                      <td>
                        <b>Exercise Response</b>
                      </td>
                      <td>
                        <b>Cardiovascular Health</b>
                      </td>
                    </tr>
                  </thead>
  
                  <tbody>
                    <tr>
                      <td>Vitamin A (retinol)</td>
                      <td>Fasting Glucose</td>
                      <td>Fasting Glucose</td>
                      <td>IL-6</td>
                      <td>Fasting Glucose</td>
                      <td>hsCRP</td>
                    </tr>
  
                    <tr>
                      <td>Vitamin B6</td>
                      <td>Hemoglobin A1c</td>
                      <td>Hemoglobin A1c</td>
                      <td>Tumor Necrosis Factor-Alpha</td>
                      <td>Hemoglobin A1c</td>
                      <td>Oxidized LDL</td>
                    </tr>
  
                    <tr>
                      <td>Homocyst(e)ine</td>
                      <td>LP-IR Score</td>
                      <td>Adiponectin</td>
                      <td>Neutrophil % (Serum)</td>
                      <td></td>
                      <td>HDL-C</td>
                    </tr>
  
                    <tr>
                      <td>Folate, RBC</td>
                      <td>Triglycerides</td>
                      <td></td>
                      <td>Eosinophil % (Serum)</td>
                      <td></td>
                      <td>HDL-P</td>
                    </tr>
  
                    <tr>
                      <td>Folate (Folic Acid),Serum</td>
                      <td></td>
                      <td></td>
                      <td>Basophil % (Serum)</td>
                      <td></td>
                      <td>Triglycerides</td>
                    </tr>
  
                    <tr>
                      <td>Vitamin B12</td>
                      <td></td>
                      <td></td>
                      <td>GlyCA</td>
                      <td></td>
                      <td>Coenzyme Q10</td>
                    </tr>
  
                    <tr>
                      <td>Methylmalonic acid</td>
                    </tr>
  
                    <tr>
                      <td>Vitamin D, 25-Hydroxy</td>
                    </tr>
  
                    <tr>
                      <td>Iron</td>
                    </tr>
  
                    <tr>
                      <td>Iron Binding Capacity (TIBC)</td>
                    </tr>
  
                    <tr>
                      <td>Ferritin</td>
                    </tr>
  
                    <tr>
                      <td>Alkaline Phosphatase</td>
                    </tr>
  
                    <tr>
                      <td>OmegaCheck</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          `,
      },
      {
        question: "If there are millions of SNPs, why does the report only show a limited number?",
        answer: `
              Most SNPs have no effect on nutritional health and therefore lack
              nutritional relevance. PureInsight<sup>&trade;</sup> includes SNPs
              that are:
            <ul>
              <li>Relevant to health</li>
              <li>Validated by published research</li>
              <li>Responsive to nutritional support</li>
            </ul>
          `,
      },
      {
        question: "Do you plan to add more SNPS to the reports?",
        answer: `
              <p>
              If you have a specific SNP that you would like us to add, 
              <a href="/contact-us">please let us know</a> 
              and our scientific team will investigate the information.
              </p>
  
              <p>
              As more SNPs are added to the report, your patients' reports will be
              automatically updated. New SNPs are added each year as scientific
              evidence becomes available
              </p>
          `,
      },
      {
        question: "If there is a SNP that’s not available on the PureInsight<sup>™</sup> report, can I still see how the patient is coding?",
        answer: `
              PureInsight<sup>&trade;</sup> does offer a Supplemental Genotype
              Report. These SNPs have yet to have enough published literature
              substantiating the effects on diet and lifestyle. At some point,
              SNPs on the Supplemental Genotype report may migrate over to the
              PureInsight<sup>&trade;</sup> report. If you have a SNP you wish to
              see that is not already on the Supplemental Genotype report, 
              <a
                className="cursor-pointer"
                href="/contact-us"
              >contact us</a>.
          `,
      },
      {
        question: "How do I know whether the patient is heterozygous or homozygous for a specific SNP?",
        answer: `
              You simply compare the patient variant vs the risk variant.
              <br />
              <div>
              For instance, in the Folate trait under the MTHFR C677T rs1801133
              SNP the risk variants are CT (Heterozygous) or TT (Homozygous). Both
              variants are shown to affect folic acid conversion.
              </div>
              <br />
              <div>
              If the patient variant is CC and the result is No Action, you know the
              patient is the wild type. Wild type and homozygous always have two
              of the same alleles whereas heterozygous have one of each chemical
              code, therefore, two different alleles.
              </div>
          `,
      },
      {
        question: "As new SNPs become available, will patients need to repeat their 23andMe<sup>®</sup> or Ancestry<sup>®</sup> tests?",
        answer: `
              No, the test only needs to be performed once. As new SNPs are added,
              the PureInsight<sup>&trade;</sup> reports will be automatically updated to reflect
              these SNPs.
          `,
      },
    ],
  },
  {
    section: "Polygenic Scores and Traits",
    faqs: [
      {
        question: "What is a polygenic score?",
        answer: `
              A polygenic score (PGS) is a measurement of genetic predisposition that analyzes many contributing SNPs, instead of just one.  Most traits are determined by many SNPs, not just one.  Since PGS looks at many SNPs, it is a more comprehensive genetic assessment and is generally more accurate than single SNP analysis.
          `,
      },
      {
        question: "What do the scores mean?",
        answer: `
              In PureInsight<sup>™</sup>, the score reflects the number of risk alleles that contribute to the trait.   The higher the score, the greater the genetic predisposition.  For example, a high score for vitamin B<sub>12</sub> indicates that an individual’s B<sub>12</sub> needs are greater than the needs of someone with a lower score.
          `,
      },
      {
        question: "How long will it take to receive the Polygenic Risk report?",
        answer: `
              Calculating the Polygenic scores should take no more than an hour to be generated, and once the score is ready, an email notification will be sent to both you and your patient. If your scores were not generated by then, please contact us <a href="/contact-us">here</a>.
          `,
      },
      {
        question: "Why were enhancements made for just a few select traits?",
        answer: `
              The accuracy of scores and intake recommendations depends on robust genome-wide association studies and development of mathematical models requiring population data on blood levels and dietary intake information. This information is currently not available for all traits. As part of our ongoing research and development, we will continue to evaluate new traits for compatibility with PGS and personalized intake recommendations.
          `,
      },
      {
        question: "How are the SNPs for the polygenic trait selected?",
        answer: `
              The contributing SNPs analyzed by the PGS were selected based on genome-wide association studies and other population data sources. SNPs with strong and statistically significant effects on nutrient levels are included in a polygenic score.
          `,
      },
      {
        question: "How accurate are the scores and intake recommendations?",
        answer: `
              The polygenic scores and personalized intake recommendations are calculated from population data showing correlations between nutrient intakes, blood levels and genotypes. These calculations are based on genetics. Practitioners are encouraged to adjust nutrient intake recommendations based on labs and other assessments.
          `,
      },
    ],
  },
  {
    section: "Privacy and Security",
    faqs: [
      {
        question: "Where can I go to learn more about my patient’s privacy?",
        answer: `
              For more information about our privacy and security practices, please review our <a href="https://www.madewithnestle.ca/privacy-policy?_wrapper_format=html">Privacy Policy</a>.
          `,
      },
      {
        question: "What privacy and security measures are taken to protect patient data?",
        answer: `
              <p>We use industry standard practices to store your genetic test results and other Personal Information you provide to us in a secured database, which employs a number of security measures. The specific security measures used are based on the sensitivity of the Personal Information collected.</p>
              <p>All connections to and from our website are encrypted using Secure Socket Layer (SSL) technology. In addition, patients’ genetic raw data is completely de-identified and encrypted in our database.</p>
              <p>As well as protecting users' information from unauthorized access from those outside of the company, we strictly limit access to this database from within the company. Only employees who need the information to perform a specific job (for example, a customer service representative) are granted access to personally identifiable information. </p>
          `,
      },
      {
        question: "How secure and private is PureInsight<sup>™</sup>?",
        answer: `
              <p>We store patients’ genetic test results without their name or other common identifying information. This means that no outside affiliate can access your patients’ genetic data and that their Personal Information has been unattached from their DNA. </p>
              <p>We use secure server software to encrypt Personal Information (including sensitive health information and genetic data), and we only partner with security companies that meet and commit to our security standards. While we cannot guarantee that loss, misuse, or alteration of data will not occur, we use reasonable efforts to prevent this.</p>
          `,
      },
      {
        question: "Will any information be shared?",
        answer: `
              Only you and your patient will have access to their genetic data file. We do not share with third parties any patient’s individual data, except as legally required or with his/her explicit consent.
          `,
      },
      {
        question: "Who can I contact for assistance?",
        answer: `
                <div class="pg-text">
                 For any questions, our Customer Service team is available by email: <a href="mailto:pureinsightpro@ca.nestle.com" class="pg-text">pureinsightpro@ca.nestle.com</a>
                </div>
                 
        `,
      },
    ],
  },
];

export const FRProviderFAQData = [
  {
    section: "FAQ sur les professionnels de santé PureInsight<sup>🅪</sup>",
    faqs: []
  },
  {
    section: "Utilisation et inscription de PureInsight<sup>🅪</sup>",
    faqs: [
      {
        question: "Qu’est-ce que PureInsight<sup>🅪</sup> ?",
        answer: `
            PureInsight<sup>🅪</sup> est notre nouvelle plateforme complémentaire qui permet de fournir des rapports de santé simples et personnalisés à vos patients, qui s’intègrent facilement dans votre environnement clinique existant. Cette plateforme simplifie le processus de collecte de données, priorise les besoins des patients et aide à développer un plan de soins holistique et scientifiquement soutenu qui se concentre sur l’alimentation, l’exercice, le mode de vie et les recommandations de suppléments. Contrairement à PureGenomics<sup>&reg;</sup>, notre plateforme PureInsight<sup>🅪</sup> permet aux prestataires de soins de santé d’utiliser des questionnaires patients et des outils validés pour fournir un plan de soins. Avec PureInsight<sup>🅪</sup>, les données génétiques et les données des tests de laboratoire ne sont plus nécessaires, mais restent facultatives pour que votre patient les télécharge.
          `,
      },
      {
        question: "Pourquoi PureGenomics<sup>®</sup> passe-t-il à PureInsight<sup>🅪</sup> ?",
        answer: `
            Nous voulons que nos services s’harmonisent avec vos besoins et notre stratégie pour l’avenir pour vous aider et vous donner les moyens de concevoir un plan de soins personnalisé, scientifiquement étayé et fondé sur des données probantes, axé sur l’alimentation, l’exercice, le mode de vie et les recommandations de suppléments pour vos patients.
          `,
      },
      {
        question: "La nouvelle image de marque introduira-t-elle un nouveau logo?",
        answer: `
            Oui, le nouveau logo est affiché sur tout le site - PureInsight<sup>🅪</sup> Powered by PureGenomics<sup>&reg;</sup>
          `,
      },
      {
        question: "Qu’est-ce qui change pour moi?",
        answer: `
              <p>L’apparence de notre site Web changera légèrement. Les pages individuelles des prestataires de soins de santé resteront les mêmes, avec des descriptions mises à jour pour souligner ce que cette plateforme offre et les outils disponibles lorsque vous vous connectez à votre compte PureInsight<sup>🅪</sup>. La plateforme PureInsight introduit de nouvelles fonctionnalités telles qu’un questionnaire multisymptôme qui simplifie et rationalise la collecte des données des patients, des questionnaires validés cliniquement tels que GAD-7, PHQ-9, PSS-4 et des rapports génétiques en option et des plaies polygéniques qui peuvent fournir des informations pour soutenir votre plan de soins.</p>
              <p>Soyez assuré que vous continuerez à recevoir les services et les fonctionnalités exceptionnels auxquels vous êtes habitué. Cette transition se fera en douceur et sans effort, ce qui nous permettra de vous aider à utiliser nos services avec encore plus d’efficacité.</p>
          `,
      },
      {
        question: "J’ai un compte PureGenomics<sup>®</sup>. Dois-je en créer un nouveau pour accéder à PureInsight<sup>🅪</sup> ?",
        answer: `
            Non, vos informations d’identification de votre compte PureGenomics<sup>&reg;</sup> peuvent être utilisées pour vous connecter et accéder à la plateforme PureInsight<sup>🅪</sup>.
          `,
      },
      {
        question: "Je n’avais pas de compte PureGenomics<sup>®</sup>. Comment puis-je accéder à PureInsight<sup>🅪</sup> ?",
        answer: `
            Si vous n’y avez pas accès, vous pouvez vous inscrire <a href="/hcp/registration">ici</a>. Si vous êtes titulaire d’un compte Pure Encapsulations<sup>&reg;</sup>, vous pouvez vous inscrire en utilisant vos informations d’identification de compte Pure.
          `,
      },
      {
        question: "L’adresse Web https://www.puregenomics.ca reste-t-elle la même?",
        answer: `
            Non, la nouvelle adresse Web est <a href='https://pureinsightpro.ca'>pureinsightpro.ca</a>
          `,
      },
      {
        question: "Y a-t-il un plan de transition précis dont je dois être au courant?",
        answer: `
            Non, nous allons progressivement passer à la nouvelle marque. L’accès à votre compte de fournisseur de soins de santé n’est pas affecté et vous pouvez utiliser de nouvelles fonctionnalités et de nouveaux outils 24 heures sur 24 et 7 jours sur 7.
          `,
      },
      {
        question: "Où puis-je envoyer mes questions complémentaires?",
        answer: `
            Pour toute question supplémentaire sur le changement de marque de PureGenomics à PureInsight<sup>🅪</sup>, veuillez cliquer sur <a href="/contact-us">Contactez-nous</a> et soumettre votre demande.
          `,
      },
      {
        question: "Quel est le coût d’utilisation de PureInsight<sup>🅪</sup> ?",
        answer: `
            PureInsight<sup>🅪</sup> est gratuit! Nous croyons qu’il faut aider tout le monde à atteindre ses objectifs de santé et de bien-être, c’est pourquoi vous pouvez profiter de tout ce que PureInsight<sup>🅪</sup> a à offrir sans frais.
          `,
      },
      {
        question: "Comment puis-je m’inscrire à un compte PureInsight<sup>🅪</sup> ?",
        answer: `
            Si vous avez vos informations d’identification de PureGenomics<sup>&reg;</sup>, utilisez-les pour vous connecter à PureInsight<sup>🅪</sup>. Si vous n’aviez pas de compte PureGenomics<sup>&reg;</sup>, inscrivez-vous à PureInsight<sup>🅪</sup> en cliquant sur ce lien : <a href="https://www.pureinsightpro.ca/hcp/registration">https://www.pureinsightpro.ca/hcp/registration</a>.
          `,
      },
      {
        question: "Lors de ma tentative d’inscription, j’ai reçu un courriel m’informant que j’avais besoin de plus d’information pour terminer mon inscription. Qu’est-ce que ça veut dire?",
        answer: `
            Cela signifie que nous devons vérifier que vous êtes un prestataire de soins de santé pour terminer la configuration du compte. Veuillez nous envoyer une copie de vos informations d’identification pour terminer votre inscription.
          `,
      },
      {
        question: "Comment un patient crée-t-il un compte PureInsight<sup>🅪</sup> ?",
        answer: `
             <p>Le professionnel de santé doit d’abord choisir "Ajouter un nouveau patient" sur son tableau de bord. Le système enverra alors un e-mail d’inscription au patient. </p>
            <p>Le patient doit cliquer sur le lien dans l’e-mail d’inscription pour activer son compte. Le patient est invité à créer un mot de passe et à se connecter à son portail patient.</p>
          `,
      },
      {
        question: "Puis-je inscrire mes patients à PureInsight<sup>🅪</sup> s’ils n’ont pas encore reçu leurs résultats de tests génétiques?",
        answer: `
            Oui, vous pouvez ajouter des patients et ils peuvent activer leur compte même s’ils n’ont pas encore reçu leurs résultats de tests génétiques de Nordic Labs, 23andMe<sup>&reg;</sup> ou Ancestry<sup>&reg;</sup>. Avec PureInsight<sup>🅪</sup>, les données génétiques ne sont pas requises mais restent une option pour vos patients.
          `,
      },
      {
        question: "Lors de la tentative d’ajout d’un nouveau patient, j’ai reçu un message d’erreur indiquant que l’adresse de messagerie est déjà utilisée. Comment dois-je procéder?",
        answer: `
            <p>Vous devrez obtenir une autre adresse e-mail du patient car l’adresse e-mail est un identifiant unique et ne peut être utilisée qu’une seule fois. 
            Étant donné que ce courriel a déjà été utilisé, le patient devra fournir une autre adresse de courriel pour le compte. </p>
            <p>Si ce patient possède un compte PureInsight<sup>🅪</sup> auprès d’un autre fournisseur utilisant l’adresse e-mail, votre patient peut également contacter le support client pour demander le transfert de son compte vers votre tableau de bord en nous envoyant un e-mail.</p>
          `,
      },
      {
        question: "Mon patient veut configurer les membres de sa famille avec des comptes PureInsight<sup>🅪</sup>. Comment font-ils ça?",
        answer: `
            <p>Les membres de la famille devront être invités par vous ou un autre fournisseur participant à créer leur propre compte. Les patients doivent être âgés de 18 ans ou plus.</p>
            <p>Chaque patient doit utiliser une adresse e-mail différente pour créer un compte.</p>
          `,
      },
      {
        question: "Et si mes patients n’étaient pas férus de technologie?",
        answer: `
                    De nombreuses questions techniques peuvent être résolues facilement par votre personnel, comme aider les patients à se connecter à leur compte et 
                    comprendre comment télécharger le fichier texte. Si le problème technique n’est pas facilement résolu, demandez à vos patients de nous <a href="https://www.pureinsightpro.ca/contact-us">contacter</a> pour obtenir de l’aide.
          `,
      },
    ],
  },
  {
    section: "Gestion des pratiques",
    faqs: [
      {
        question: "Comment ajouter des membres du personnel à ma pratique?",
        answer: `
            Une fois connecté, cliquez sur votre nom dans le coin supérieur droit de la page pour accéder à la page Gestion du personnel. Cliquez ensuite sur le bouton Ajouter un nouveau membre du personnel pour ouvrir le formulaire d’ajout d’un nouveau membre du personnel. Remplissez les champs requis, attribuez les autorisations souhaitées, puis cliquez sur Enregistrer pour terminer. Votre nouveau membre du personnel recevra un e-mail pour définir son mot de passe et activer son compte.
          `,
      },
      {
        question: "Que peuvent faire les membres du personnel?",
        answer: `
            Les membres du personnel peuvent ajouter des patients, afficher la liste des patients, envoyer des rappels aux patients et afficher les rapports des patients. En fonction des autorisations facultatives sélectionnées par le propriétaire du cabinet, les membres du personnel peuvent également télécharger les fichiers génétiques et les fichiers de laboratoire du patient, afficher les recommandations, ainsi que le catalogue et les substitutions. Les membres du personnel ne sont pas autorisés à créer des recommandations, à modifier des recommandations ou à effectuer des tâches de gestion des pratiques telles que l’ajout d’autres membres du personnel.
          `,
      },
      {
        question: "Comment gérer les autorisations des membres de mon personnel?",
        answer: `
            Les autorisations facultatives sont spécifiques à l’utilisateur et peuvent être configurées à partir de la page Gestion du personnel. Une fois connecté, cliquez sur votre nom dans le coin supérieur droit de la page pour accéder à la page Gestion du personnel. Sélectionnez ensuite un membre du personnel dans la liste et cliquez sur Gérer pour ouvrir les détails du compte de ce membre du personnel. Attribuez les autorisations souhaitées et cliquez sur Mettre à jour pour terminer.
          `,
      },
      {
        question: "Comment puis-je éliminer un membre du personnel de mon cabinet?",
        answer: `
            Une fois connecté, cliquez sur votre nom dans le coin supérieur droit de la page pour accéder à la page Gestion du personnel. Sélectionnez ensuite un membre du personnel dans la liste et cliquez sur "Archiver le membre du personnel". Le statut de leur compte sera mis à jour et archivé et ils ne pourront plus accéder à leur compte.
          `,
      },
      {
        question: "Comment puis-je mettre à jour mes informations personnelles?",
        answer: `
            Toutes les modifications peuvent être effectuées à partir du tableau de bord de votre compte en cliquant sur <b>Mon compte</b>, situé sous l’endroit où votre nom apparaît en haut de la page.
          `,
      },
      {
        question: "J’ai un patient que je ne vois plus. Comment supprimer ce patient de mon tableau de bord?",
        answer: `
            Les patients ne peuvent pas être définitivement supprimés de votre tableau de bord. Si un patient n’est plus actif dans votre cabinet, vous pouvez l’archiver à partir de votre tableau de bord. Pour archiver, cliquez sur Modifier le patient dans la ligne correspondante.
          `,
      },
    ],
  },
  {
    section: "Personnalisation et questionnaires",
    faqs: [
      {
        question: "Comment PureInsight<sup>🅪</sup> renforce-t-il la personnalisation?",
        answer: `
            PureInsight<sup>🅪</sup> vous permet de recueillir une variété d’informations cliniquement pertinentes sur votre patient à partir de questionnaires, d’ADN et de tests sanguins pour vous aider à identifier clairement les domaines prioritaires et aider vos patients à atteindre leurs objectifs de santé et de bien-être. Les données ADN fournissent des informations nutrigénomiques pour mieux comprendre comment la génétique de vos patients peut influencer leur santé, que vous pouvez utiliser pour faire des suppléments hautement personnalisés et des recommandations de style de vie basées sur la génétique des patients et les résultats du questionnaire. Un professionnel de la santé ne peut pas diagnostiquer ou prescrire de médicaments à l’aide des services PureInsight<sup>🅪</sup>, y compris le rapport nutrigénomique.
          `,
      },
      {
        question: "Qu’est-ce que le Multi-Symptom Questionnaire (MSQ) ?",
        answer: `
            Le MSQ est pour un mode de vie plus personnalisé et des recommandations diététiques. Il s’agit d’un questionnaire court et attrayant qui prend 5 à 7 minutes à remplir par les patients. Le questionnaire vous fournit des informations plus spécifiques sur votre patient à utiliser en combinaison avec ses données génétiques pour un plan personnalisé complet. Bien que les données génétiques puissent aider à fournir un plan personnalisé complet, il n’est plus nécessaire et votre patient peut choisir de remplir le questionnaire MSQ seulement. Les réponses à la QMS permettent de peaufiner davantage les recommandations. Les exemples comprennent les habitudes d’un patient (telles que la consommation de caféine), ses préférences, ses objectifs, et d’autres.
          `,
      },
      {
        question: "Mon patient peut-il remplir le questionnaire multi-symptômes s’il n’a pas encore son fichier de données génétiques brutes?",
        answer: `
            Oui, un patient peut remplir le questionnaire avant de recevoir son fichier de données génétiques brutes. Bien que les données génétiques demeurent une option, elles ne sont plus nécessaires. Vous pouvez envoyer une invitation au patient à partir de votre tableau de bord patient. Vous serez en mesure d’afficher les réponses au questionnaire à partir de votre tableau de bord patient. Pour afficher les réponses, cliquez sur la flèche en regard du nom de votre patient, puis cliquez sur Afficher les réponses sous Questionnaire.
          `,
      },
      {
        question: "Un patient peut-il modifier ses réponses?",
        answer: `
            Les patients auront la possibilité de modifier leurs réponses avant de soumettre leurs réponses finales. Ils n’auront qu’une seule occasion d’apporter les changements nécessaires. Si vous ou eux avez besoin de modifier des réponses au-delà de cette date, un nouveau questionnaire devra être rempli (qui peut être demandé à partir de votre tableau de bord).
          `,
      },
      {
        question: "Mon patient peut-il demander un nouveau questionnaire multi-symptômes après plusieurs mois de soins?",
        answer: `
            Oui. Dans le tableau de bord du patient, votre patient peut cliquer sur la flèche en regard du nom du patient et cliquer sur Demander un nouveau questionnaire.
          `,
      },
      {
        question: "Sur quelle base se fonde l’utilisation des questionnaires supplémentaires pour les patients? - Questionnaire sur le trouble anxieux généralisé (GAD-7), Questionnaire sur la santé du patient (PHQ-9) et Questionnaire sur l’échelle de stress perçu (PSS-4) ?",
        answer: `
            <p>En fonction de la façon dont les réponses au questionnaire multisymptomatique sont traitées, vous pouvez demander à votre patient de remplir certains questionnaires supplémentaires. Ces instruments d’évaluation supplémentaires peuvent vous aider à mieux comprendre l’état de santé et de bien-être de votre patient.</p>
            <p>Ces questionnaires supplémentaires n’ont pas d’impact sur le score du questionnaire multisymptomatique du patient, mais permettent d’approfondir la compréhension des besoins de santé et de bien-être du patient.</p>
          `,
      },
    ],
  },
  {
    section: "Tests génétiques, laboratoires et téléchargement",
    faqs: [
      {
        question: "Quels laboratoires de tests génétiques sont compatibles avec PureInsight<sup>🅪</sup> ?",
        answer: `
            PureInsight<sup>🅪</sup> interprète uniquement les données génétiques de DNALabs, Nordic Labs, 23andMe<sup>&reg;</sup> et Ancestry<sup>&reg;</sup>.
          `,
      },
      {
        question: "En tant que fournisseur de soins de santé, comment puis-je télécharger mes propres résultats de test Nordic Labs, 23andMe<sup>®</sup> ou Ancestry<sup>®</sup> sur PureInsight<sup>🅪</sup> ?",
        answer: `
            Lorsque vous recevez les résultats des tests de Nordic Labs, 23andMe<sup>&reg;</sup> ou Ancestry<sup>&reg;</sup>, connectez-vous à votre compte PureInsight<sup>🅪</sup>. Pour télécharger et traiter vos propres données génétiques, procédez comme suit :
           <ol type="1" class="lh-lg">
                <li>Cliquez sur <b><i>Ajouter un nouveau patient.</i></b></li>
                <li>Vérifiez l’option <b><i>Ajouter moi-même en tant que patient.</i></b></li>
                <li>Entrez toute information manquante.</li>
                <li>Si vous souhaitez répondre au questionnaire, cochez la case.</li>
                <li>Si vous avez des laboratoires à télécharger, cochez la case.</li>
                <li>Cliquez sur <b><i>Terminé.</i></b> La plateforme vous amènera à votre liste de patients.</li>
                <li>Sous <b><i>Génétique</i></b>, Cliquez sur <b><i>Démarrer Le Téléchargement.</i></b></li>
            </ol>

          `,
      },
      {
        question: "J’ai remarqué que Nordic Labs, 23andMe<sup>®</sup> et Ancestry<sup>®</sup> offrent différents kits d’ADN que vous pouvez acheter. Y a-t-il une trousse d’ADN particulière que les patients devraient acheter s’ils sont intéressés à utiliser votre plateforme?",
        answer: `
            Tout kit ADN vendu par Nordic Labs, 23andMe<sup>&reg;</sup> ou Ancestry<sup>&reg;</sup> est compatible avec PureInsight<sup>🅪</sup>.
          `,
      },
      {
        question: "Puis-je commander un kit de test Nordic Labs, 23andMe<sup>®</sup> ou Ancestry<sup>®</sup> via PureInsight<sup>🅪</sup> ?",
        answer: `
            Non. Vous devez commander la trousse d’ADN directement auprès de Nordic Labs, 23andMe<sup>&reg;</sup> ou Ancestry<sup>&reg;</sup> ou d’autres détaillants autorisés. Veuillez noter que PureInsight<sup>🅪</sup> n’est pas affilié à Nordic Labs, 23andMe<sup>&reg;</sup> ou Ancestry<sup>&reg;</sup>, ni approuvé par eux.
          `,
      },
      {
        question: "Comment les patients téléchargent-ils leurs données brutes de 23andMe<sup>®</sup> ou d’Ancestry<sup>®</sup> et téléchargent-ils ces données dans PureInsight<sup>🅪</sup> ?",
        answer: `
          <div  style="">
            <div class="pgs-text my-2">
                <div>
                <div>
                    <span class="text-decoration-underline fw-bold">Via 23andMe</span><sup>®</sup> : Une fois connecté à son compte PureInsight<sup>🅪</sup>, le patient devra cliquer sur le lien 23andMe<sup>®</sup> pour se connecter à son compte. Le patient fera défiler la page vers le bas, cochera une case confirmant qu’il télécharge à partir d’un serveur sécurisé, puis cliquera sur <b><i>Envoyer la demande.</i></b> 23andMe<sup>®</sup> nécessite un processus d’authentification en 2 étapes. Le patient recevra un e-mail de 23andMe<sup>®</sup> pour lui faire savoir que son téléchargement de données brutes est prêt. L’appel à l’action au bas de l’e-mail de 23andMe<sup>®</sup> renvoie le patient à son compte 23andMe<sup>®</sup> où il peut désormais télécharger son fichier de données brutes et l’enregistrer sur son ordinateur. Enfin, le patient retourne dans son tableau de bord patient PureInsight<sup>🅪</sup> et clique sur <b><i>Télécharger vos données,</i></b> sélectionne son fichier à partir de l’emplacement enregistré et clique sur <b><i>Démarrer le téléchargement.</i></b>
                </div><br>
                <div>Une fois leurs données téléchargées, le patient pourra voir son rapport patient. Le rapport du patient ne comprend pas de supplément ou de recommandations de laboratoire. Vous serez également informé que leur rapport est prêt à être consulté à partir du tableau de bord de votre praticien.</div><br>
                <div>
                    <span class="text-decoration-underline fw-bold">Via Ancestry</span><sup>®</sup> : Une fois connecté à son compte PureInsight<sup>🅪</sup>, le patient devra cliquer sur le lien Ancestry.com pour se connecter à son compte. Des instructions sont fournies sur cette page pour que le patient télécharge son fichier de données génétiques brutes.
                </div><br>
                <div>Ancestry.com nécessite un processus d’authentification en 2 étapes. Le patient recevra un e-mail de la société de test pour l’informer que son téléchargement de données brutes est prêt. L’appel à l’action <b><i>Confirmer le téléchargement des données</i></b> au bas de l’e-mail de l’entreprise renvoie le patient à son compte Ancestry.com où il peut désormais télécharger son fichier de données brutes et l’enregistrer sur son ordinateur. Enfin, le patient retourne dans son tableau de bord patient PureInsight<sup>🅪</sup> et clique sur <b><i>Télécharger vos données,</i></b> sélectionne son fichier à partir de l’emplacement enregistré et clique sur <b><i>Démarrer le téléchargement.</i></b>
                </div><br>
                <div>Une fois leurs données téléchargées, le patient pourra voir son rapport patient. Le rapport du patient ne comprend pas de supplément ou de recommandations de laboratoire. Vous serez également informé que leur rapport est prêt à être consulté à partir du tableau de bord de votre praticien.</div><br>
                </div>
            </div>
            </div>
  `,
      },
      {
        question: "Puis-je télécharger des données pour mes patients?",
        answer: `
          Oui, vous pouvez télécharger les données du patient pour eux. Ils doivent vous fournir leur fichier de données génétiques brutes ou l’accès à leur compte de kit de test.
            <br />
            <br />
            <div>
            Pour télécharger les données de votre patient, vous devez d’abord créer le compte PureInsight<sup>🅪</sup> du patient. Cliquez sur <b><i>Ajouter un nouveau patient.</i></b> Sur la page suivante, vous serez invité à entrer le nom du patient, son adresse e-mail, sa date de naissance et son sexe. Dans la section Génétique, sélectionnez l’option <b><i>Praticien va télécharger,</i></b> qui vous donnera la possibilité de télécharger leur fichier de données.
            </div>
            <br />
            <div>
            Si le patient possède déjà un compte PureInsight<sup>🅪</sup>, vous allez sur <b>Afficher les patients</b>, puis cliquez sur la flèche droite située sur la ligne du nom du patient. Cliquez ensuite sur <b>Démarrer le téléchargement</b> et choisissez leur fichier de données génétiques.
            </div>
          `,
      },
      {
        question: "J’ai reçu un message d’erreur lorsque j’ai essayé de télécharger les données génomiques de mon patient. Comment je peux arranger ça?",
        answer: `
            Il existe trois raisons pour lesquelles une erreur peut se produire lors du téléchargement des données génomiques de votre patient.
            <ol type="1">
              <li>Les données proviennent d’une société de test non compatible. PureInsight<sup>🅪</sup> est uniquement compatible avec les résultats des tests 23andMe<sup>&reg;</sup> ou Ancestry<sup>&reg;</sup>.</li>
              <li>Votre patient a fourni des résultats dans un format autre qu’un texte ou un fichier zip (comme un PDF). Vérifiez que le fichier que vous téléchargez est au format.txt ou.zip. Il s’agit également du format de téléchargement courant de 23andMe<sup>&reg;</sup> ou Ancestry<sup>&reg;</sup>.</li>
              <li>Les données du patient sont illisibles. Les données peuvent devenir corrompues et sont inintelligibles par notre système. Veuillez demander à votre patient de télécharger à nouveau le fichier à partir du site Web de test et de vous le renvoyer. Lorsque leur appareil leur demande s’ils souhaitent ouvrir ou enregistrer le fichier, ils devront sélectionner Enregistrer car l’ouverture du fichier peut entraîner une corruption.</li>
            </ol>
          `,
      },
      {
        question: "Combien de temps après avoir terminé le test génétique 23andMe<sup>®</sup> ou Ancestry<sup>®</sup> les patients obtiendront-ils leurs résultats?",
        answer: `
            Les deux sociétés déclarent qu’une période de 6 à 8 semaines peut être prévue. Une fois que le patient a reçu une notification que les résultats sont prêts, il est prêt à se connecter au site Web de la société de test et à télécharger son fichier de données génétiques brutes.
          `,
      },
      {
        question: "Comment fonctionne la fonctionnalité Lab Upload?",
        answer: `
            La fonctionnalité de téléchargement en laboratoire affiche facilement l’état des nutriments pour faciliter la prise de décision. Cette fonctionnalité facultative permet à un praticien de télécharger les résultats de fournisseurs de laboratoire tels que labcorp et Quest. Vous pouvez télécharger l’intégralité du fichier PDF ou entrer les résultats manuellement. Un moteur piloté par l’IA extrait les données pertinentes pour les traits dans PureInsight<sup>🅪</sup>. Voici un exemple de phénotype affiché avec le génotype.
          `,
      },
      {
        question: "Comment entrer un résultat de laboratoire?",
        answer: `
            Dans la liste des patients, cliquez sur la flèche située sous Résultats de laboratoire, puis sur Démarrer le téléchargement. Dans la page Saisie des résultats de laboratoire, entrez le nom du fournisseur et cliquez sur Saisir des résultats de laboratoire et suivez les instructions et l’exemple. Ajouter le nom du test Lab, les résultats, les unités et les valeurs de référence. Une fois que vous avez entré tous les laboratoires pertinents, vous pouvez enregistrer les résultats.
          `,
      },
      {
        question: "Quelle est la probabilité d’un faux positif?",
        answer: `
            La probabilité d’un faux positif est l’information que vous devez obtenir du fournisseur de services génétiques avec lequel vous avez travaillé (Nordic Labs, 23andMe<sup>&reg;</sup> ou Ancestry<sup>&reg;</sup>).
          `,
      },
      {
        question: "Mes patients peuvent uniquement consulter les données génétiques brutes de 23andMe<sup>®</sup>, mais pas les télécharger, que dois-je leur dire?",
        answer: `
            Malheureusement, nous n’avons aucun contrôle sur le système 23andme<sup>&reg;</sup>. Néanmoins, nous maintenons une communication continue avec eux et, à l’heure actuelle, ils n’ont pas de délai précis pour régler le problème. Je suggère de conseiller à vos patients de communiquer avec 23andMe<sup>&reg;</sup> pour obtenir des renseignements sur d’autres méthodes d’obtention de leur fichier génétique afin qu’ils puissent le télécharger dans PureInsight<sup>🅪</sup> et obtenir leur rapport de patient. Par ailleurs, notre système est également compatible avec les données génétiques d’Ancestry<sup>&reg;</sup>, un autre service de test, auprès duquel vos patients peuvent souhaiter obtenir leurs données génétiques.
          `,
      },
    ],
  },
  {
    section: "Rapport",
    faqs: [
      {
        question: "À quoi ressemble un rapport PureInsight<sup>🅪</sup> ?",
        answer: `
            PureInsight<sup>🅪</sup> fournit un rapport interactif qui permet aux praticiens et aux patients d’explorer leurs résultats par catégorie de trait. Les praticiens ont également accès à un rapport sommaire imprimable qui regroupe tous les résultats en un seul endroit. Les deux rapports sont visibles par le professionnel de santé.
          `,
      },
      {
        question: "Comment un patient génère-t-il son rapport PureInsight<sup>🅪</sup> ?",
        answer: `
            Pour qu’un rapport PureInsight<sup>🅪</sup> soit généré, le fichier de données génétiques brutes du patient doit être téléchargé dans PureInsight<sup>🅪</sup>.
  
            Une fois connectés à leur compte patient PureInsight<sup>🅪</sup>, ils auront deux options : se connecter à leur compte sur le site Web de leur entreprise de tests pour télécharger leur fichier de données génétiques brutes ou, s’ils ont déjà leur fichier de données téléchargé, simplement télécharger leurs données. Lorsque le fichier est téléchargé et enregistré sur leur appareil, ils sont prêts à télécharger leurs données dans PureInsight<sup>🅪</sup> et à générer leur rapport.
          `,
      },
      {
        question: "Combien de temps faudra-t-il à PureInsight<sup>🅪</sup> pour interpréter les données de mes patients?",
        answer: `
            Une fois le fichier de données génétiques téléchargé, à votre discrétion, vous aurez l’occasion d’examiner les données génétiques et les recommandations, puis de publier le rapport du patient PureInsight<sup>🅪</sup> et vos recommandations à l’intention du patient. Le patient est invité à planifier une consultation avec vous pour examiner son rapport patient PureInsight<sup>🅪</sup>.
          `,
      },
      {
        question: "Où puis-je trouver les résultats de laboratoire de mes patients dans le rapport PureInsight<sup>🅪</sup> ?",
        answer: `
            Les résultats du laboratoire se trouvent dans la colonne Recommandations d’évaluation du rapport HTML et du rapport PDF imprimable.
            <br />
            Vous pouvez également afficher les résultats à partir du tableau de bord du patient en cliquant sur la flèche située à la fin de la ligne du patient, puis sur <b><i>Afficher les résultats</i></b>.
          `,
      },
      {
        question: "Ce rapport est différent de ce à quoi j’ai l’habitude. Avez-vous mis à jour vers une version plus récente?",
        answer: `
            Oui. Nous avons fait évoluer nos rapports pour tenir compte des connaissances scientifiques actuelles. Nous proposons des vidéos tutorielles qui mettent en évidence les nouveaux changements apportés aux fonctionnalités du site Web et aux rapports.
          `,
      },
      {
        question: "Quelle est la différence entre le rapport que je partage avec mes patients et le rapport complet sur l’ADN?",
        answer: `
            Le rapport que vous partagez se concentre sur des domaines spécifiques de l’état de santé et de bien-être d’un patient en vous permettant de fournir des recommandations personnalisées. Le rapport ADN complet fournit des détails sur les traits génétiques analysés de votre patient.
          `,
      },
      {
        question: "Comment enregistrer des rapports sur mon ordinateur?",
        answer: `
                        <div>
                        <p>Pour enregistrer le rapport sur votre ordinateur, cliquez sur <b><i>Afficher et imprimer le rapport du praticien</i></b> et/ou <b><i>Afficher et imprimer le rapport du patient</i></b> en haut de la page. Vous verrez un bouton de téléchargement sur l’écran suivant.</p>
                        
                        Lors de l’affichage des rapports passés, il se peut que le bouton Afficher et imprimer le rapport ne s’affiche pas. Au lieu de cela, vous pouvez voir un bouton <b><i>Imprimer</i></b> en haut de la page, veuillez cliquer dessus pour enregistrer le rapport. Dans les options de l’imprimante, sélectionnez <b><i>Imprimer au format PDF.</i></b> Le rapport sera alors enregistré sur votre ordinateur.
                        </div>
          `,
      },
      {
        question: "Puis-je modifier une recommandation personnelle déjà partagée avec un patient?",
        answer: `
            Non, mais une nouvelle recommandation peut être créée à chaque fois que le patient soumet de nouvelles données ou remplit un questionnaire.
          `,
      },
      {
        question: "Les rapports peuvent-ils être générés sans recommandations supplémentaires?",
        answer: `
            Les rapports des patients sont générés sans recommandations supplémentaires jusqu’à ce que le fournisseur modifie et envoie les recommandations. Une fois que ces recommandations sont enregistrées et envoyées au patient, elles sont affichées sur le rapport du patient.
          `,
      },
    ],
  },
  {
    section: "Recommandations de supplément",
    faqs: [
      {
        question: "Si les taux sanguins ou d’autres valeurs de laboratoire ne montrent pas un faible taux de nutriments, mais que le rapport PureInsight<sup>🅪</sup> recommande un supplément, que dois-je faire?",
        answer: `
            Un SNP seul ne peut pas déterminer un besoin accru en nutriments. Cependant, la présence d’un SNP permet d’identifier si un patient est susceptible d’avoir besoin de quantités supplémentaires d’une forme spécifique d’un nutriment. Une évaluation clinique est nécessaire pour prendre des décisions finales concernant la nécessité de la supplémentation.
          `,
      },
      {
        question: "Comment PureInsight<sup>🅪</sup> contribue-t-il à augmenter l’efficacité avec des recommandations de suppléments?",
        answer: `
            Cela réduit le temps consacré à la création de recommandations de suppléments, qui était un problème précédent par les commentaires des praticiens. En outre, la posologie et la fréquence du supplément sont auto-remplies à partir de l’étiquette du produit.
          `,
      },
      {
        question: "Existe-t-il un moyen d’imprimer les recommandations du supplément avec des modifications?",
        answer: `
            Une fois que vous avez édité et envoyé les recommandations du supplément patient, il apparaît dans le rapport du patient et vous pouvez imprimer le rapport du patient entier ou simplement la page contenant les recommandations. Ces recommandations peuvent également être observées par le patient. De plus, vous avez la possibilité d’ajouter n’importe quel complément à la liste des recommandations en appuyant simplement sur le bouton '+ AJOUTER UNE RECOMMANDATION DE PRODUIT ' et une zone de texte libre s’ouvrira.
          `,
      },
      {
        question: "Le rapport contient-il des recommandations de supplément et des suggestions de posologie?",
        answer: `
            <div>
            <p>Le rapport du fournisseur de soins de santé comprend des recommandations de supplément de Pure Encapsulations<sup>&reg;</sup>, ainsi que des doses suggérées.</p>
  
            <p>PureInsight<sup>🅪</sup> priorise une liste de suggestions de suppléments en fonction des traits pour lesquels Considérer l’action est suggéré. La fonction de recommandation de supplément améliore la personnalisation en renseignant automatiquement les doses, quantités et instructions recommandées que vous pouvez modifier. La plate-forme permet d’envoyer des recommandations de supplément au patient et de suivre facilement si elles ont été examinées.</p>
  
            <p>C’est au fournisseur de soins de santé de décider quelles recommandations de supplément seront visibles dans le rapport du patient. Vous ne pouvez modifier les recommandations du supplément que via le rapport interactif. Les modifications apportées seront reflétées dans le rapport sommaire imprimable. Une fois que votre liste de recommandations de supplément est prête à être consultée par le patient, vous pouvez transmettre les recommandations au patient afin qu’il puisse les voir dans ses rapports.</p>
            </div>
          `,
      },
      {
        question: "Dois-je distribuer tous les suppléments recommandés pour une catégorie de trait donnée?",
        answer: `
            Non. Pour plusieurs traits, plusieurs suppléments sont suggérés. Cependant, le patient peut ne pas avoir besoin de tous les médicaments. La sélection peut être affinée en évaluant les niveaux de nutriments ou d’autres types d’essais que vous incluriez normalement dans les évaluations des patients.
          `,
      },
      {
        question: "Comment mes patients peuvent-ils commander les suppléments recommandés?",
        answer: `
            Les professionnels de santé qui ont un compte Pure Encapsulations Pro peuvent commander des produits à garder sous la main pour les achats des patients, ou peuvent diriger leurs patients vers <a href="https://www.pureencapsulations.ca/fr/ou-acheter" target="_blank">https://www.pureencapsulations.ca/fr/ou-acheter</a>.
          `,
      },
    ],
  },
  {
    section: "Nutrigénomique et caractères",
    faqs: [
      {
        question: "Que sont les traits?",
        answer: `
            Les traits sont des caractéristiques spécifiques comme la couleur des yeux, le groupe sanguin ou la capacité à tolérer le lactose qui sont basées sur les variations génétiques uniques d’une personne, ainsi que des facteurs environnementaux.
          `,
      },
      {
        question: "Que sont les polymorphismes mononucléotidiques (SNP) ?",
        answer: `
            Les SNP (polymorphismes mononucléotidiques) se produisent normalement dans l’ADN d’une personne et chaque SNP représente une variation génétique au sein d’un seul bloc de construction d’ADN dans une séquence génique, appelée nucléotide. Bien que la plupart des SNP n’aient aucun effet sur la santé ou le développement d’une personne, certains peuvent aider à éclairer les choix de vie et les résultats en matière de santé et de bien-être.
          `,
      },
      {
        question: "Quelles catégories de caractéristiques sont prises en charge par PureInsight<sup>🅪</sup> ?",
        answer: `
            Les rapports PureInsight<sup>🅪</sup> offrent des informations sur les catégories de caractéristiques suivantes :
            <ul>
              <li>Vitamines, minéraux et oméga-3</li>
              <li>Désintoxication</li>
              <li>Métabolisme du glucose</li>
              <li>Métabolisme et gestion du poids</li>
              <li>Santé cognitive et mémoire</li>
              <li>Santé immunitaire</li>
              <li>Énergie et forme physique</li>
              <li>Santé cardiovasculaire</li>
              <li>Santé gastro-intestinale</li>
            </ul>
          `,
      },
      {
        question: "Quels sont les fichiers de données génétiques brutes des sociétés de test qui fournissent le plus de SNP sur votre rapport PureInsight<sup>🅪</sup> ?",
        answer: `
            <div class="pgs-text my-2">
            <div>
            23andMe<sup>®</sup> et Ancestry<sup>®</sup> utilisent une "puce de génotypage" pour détecter les données ADN des patients. Les ID de cluster SNP de référence, également appelés numéros rs, qui sont inclus dans les données brutes dépendent de la puce utilisée. Ces entreprises mettent couramment à jour leurs versions de puces pour diverses raisons, ce qui peut avoir une incidence sur les données brutes disponibles pour une analyse plus approfondie. 
            Vous trouverez ci-dessous un graphique qui présente les différences entre les versions de puce les plus courantes utilisées dans PureInsight<sup>🅪</sup>. (Le "X" indique que le SNP est inclus dans cette version de puce).</div>
            <div class="overflow-x-scroll overflow-x-lg-hidden">
                <table class="pg-table my-3 text-center fw-bold">
                <thead>
                    <tr>
                    <td rowspan="2" class="text-start">SNP Testé</td>
                    <td colspan="2">23andMe</td>
                    <td>Ancestry</td>
                    </tr>
                    <tr>
                    <td>Version 4</td>
                    <td>Version 5</td>
                    <td>Version 2</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td class="text-start">EDN1 (rs5370)</td>
                    <td><i class="fas fa-times"></i></td>
                    <td></td>
                    <td><i class="fas fa-times"></i></td>
                    </tr>
                    <tr>
                    <td class="text-start">GATA3 (rs4143094)</td>
                    <td></td>
                    <td><i class="fas fa-times"></i></td>
                    <td><i class="fas fa-times"></i></td>
                    </tr>
                    <tr>
                    <td class="text-start">AOC1 (rs10156191)</td>
                    <td><i class="fas fa-times"></i></td>
                    <td></td>
                    <td><i class="fas fa-times"></i></td>
                    </tr>
                    <tr>
                    <td class="text-start">BCMO1 (rs12934922)</td>
                    <td><i class="fas fa-times"></i></td>
                    <td><i class="fas fa-times"></i></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td class="text-start">TCN2 (rs1801198)</td>
                    <td><i class="fas fa-times"></i></td>
                    <td></td>
                    <td><i class="fas fa-times"></i></td>
                    </tr>
                    <tr>
                    <td class="text-start">SLC23A1 (rs33972313)</td>
                    <td></td>
                    <td><i class="fas fa-times"></i></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td class="text-start">DHCR7 (rs12785878)</td>
                    <td></td>
                    <td><i class="fas fa-times"></i></td>
                    <td><i class="fas fa-times"></i></td>
                    </tr>
                    <tr>
                    <td class="text-start">CYP2R1 (rs10741657)</td>
                    <td></td>
                    <td><i class="fas fa-times"></i></td>
                    <td><i class="fas fa-times"></i></td>
                    </tr>
                    <tr>
                    <td class="text-start">GPx1P1 (rs1050450)</td>
                    <td><i class="fas fa-times"></i></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <td class="text-start">DRD2 (rs6277)</td>
                    <td><i class="fas fa-times"></i></td>
                    <td><i class="fas fa-times"></i></td>
                    <td></td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>

          `,
      },
      {
        question: "Quels sont les marqueurs de laboratoire qui coïncident avec les traits PureInsight<sup>🅪</sup> ?",
        answer: `
         <div class="pgs-text my-2">
            <div>Les tests suivants peuvent être extraits des rapports labcorp et Quest qui correspondent aux traits PureInsight<sup>🅪</sup> ou les résultats de laboratoire peuvent être entrés manuellement à partir d’autres fournisseurs.</div>
            <div class="overflow-x-scroll overflow-x-lg-hidden">
                <table class="pg-table my-3">
                <thead>
                    <tr>
                    <td><b>Vitamines, Minéral &amp; Oméga-3 Profil</b></td>
                    <td><b>Métabolisme du Glucose</b></td>
                    <td><b>Gestion du Poids</b></td>
                    <td><b>Santé Immunitaire</b></td>
                    <td><b>Réponse à l'Exercice</b></td>
                    <td><b>Santé Cardiovasculaire</b></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>Vitamine A (rétinol)</td>
                    <td>Glucose à Jeun</td>
                    <td>Glucose à Jeun</td>
                    <td>IL-6</td>
                    <td>Glucose à Jeun</td>
                    <td>hsCRP</td>
                    </tr>
                    <tr>
                    <td>Vitamine B6</td>
                    <td>Hémoglobine A1c</td>
                    <td>Hémoglobine A1c</td>
                    <td>Facteur De Nécrose Tumorale Alpha</td>
                    <td>Hémoglobine A1c</td>
                    <td>LDL oxydée</td>
                    </tr>
                    <tr>
                    <td>Homocyst(e)ine</td>
                    <td>Score LP-IR</td>
                    <td>Adiponectine</td>
                    <td>Neutrophiles % (Sérum)</td>
                    <td></td>
                    <td>HDL-C</td>
                    </tr>
                    <tr>
                    <td>Folate, globules rouges</td>
                    <td>Triglycérides</td>
                    <td></td>
                    <td>Eosinophiles % (Sérum)</td>
                    <td></td>
                    <td>HDL-P</td>
                    </tr>
                    <tr>
                    <td>Folate (Acide Folique), Sérum</td>
                    <td></td>
                    <td></td>
                    <td>Basophiles % (Sérum)</td>
                    <td></td>
                    <td>Triglycérides</td>
                    </tr>
                    <tr>
                    <td>Vitamine B12</td>
                    <td></td>
                    <td></td>
                    <td>GlyCA</td>
                    <td></td>
                    <td>Coenzyme Q10</td>
                    </tr>
                    <tr>
                    <td>Acide méthylmalonique</td>
                    </tr>
                    <tr>
                    <td>Vitamine D, 25-hydroxy</td>
                    </tr>
                    <tr>
                    <td>Fer</td>
                    </tr>
                    <tr>
                    <td>Capacité de fixation du fer (TIBC)</td>
                    </tr>
                    <tr>
                    <td>Ferritine</td>
                    </tr>
                    <tr>
                    <td>Phosphatase Alcaline</td>
                    </tr>
                    <tr>
                    <td>OmegaCheck</td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
`,
      },
      {
        question: "S'il y a des millions de SNP, pourquoi le rapport n'en montre-t-il qu'un nombre limité ?",
        answer: `
            La plupart des SNP n'ont aucun effet sur la santé nutritionnelle et manquent donc de pertinence nutritionnelle. PureInsight<sup>🅪</sup> inclut des SNP qui sont :
            <ul>
              <li>Pertinent pour la santé</li>
              <li>Validée par une recherche publiée</li>
              <li>Réactivité à l'assistance nutritionnelle</li>
            </ul>
          `,
      },
      {
        question: "Prévoyez-vous d'ajouter d'autres SNPS aux rapports ?",
        answer: `
            Si vous avez un SNP spécifique que vous souhaitez que nous ajoutions, veuillez nous le faire savoir et notre équipe scientifique étudiera l'information.
  
            À mesure que d'autres SNP sont ajoutés au rapport, les rapports de vos patients seront automatiquement mis à jour. De nouveaux SNP sont ajoutés chaque année à mesure que des preuves scientifiques deviennent disponibles.
          `,
      },
      {
        question: "S'il y a un SNP qui n'est pas disponible sur le rapport PureInsight<sup>🅪</sup>, puis-je quand même voir comment le patient code ?",
        answer: `
            PureInsight<sup>🅪</sup> offre un rapport de génotype supplémentaire. Ces SNP n’ont pas encore fait l’objet de suffisamment de publications attestant de leurs effets sur l’alimentation et le mode de vie. À un moment donné, les SNP du rapport de génotype supplémentaire peuvent migrer vers le rapport PureInsight<sup>🅪</sup>. Si vous avez un SNP que vous souhaitez voir qui n'est pas déjà sur le rapport de génotype supplémentaire, <a href="/contact-us">contactez-nous</a>.
          `,
      },
      {
        question: "Comment savoir si le patient est hétérozygote ou homozygote pour un SNP spécifique ?",
        answer: `
            <p>Il suffit de comparer la variante du patient à la variante du risque. Par exemple, dans le trait Folate sous le MTHFR C677T rs1801133 SNP, les variantes de risque sont CT (hétérozygotes) ou TT (homozygotes). Il a été démontré que les deux variantes affectent la conversion de l'acide folique.</p>  
            <p>Si la variante du patient est CC et que le résultat est Aucune action, vous savez que le patient est du type sauvage. Le type sauvage et l'homozygote ont toujours deux des mêmes allèles alors que les hétérozygotes ont un de chaque code chimique, donc deux allèles différents.</p>
          `,
      },
      {
        question: "Lorsque de nouveaux SNP seront disponibles, les patients devront-ils répéter leurs tests Nordic Labs, 23andMe<sup>®</sup> ou Ancestry<sup>®</sup> ?",
        answer: `
            Non, le test ne doit être effectué qu'une seule fois. À mesure que de nouveaux SNP sont ajoutés, les rapports PureInsight<sup>🅪</sup> sont automatiquement mis à jour pour refléter ces SNP.
          `,
      },
    ],
  },
  {
    section: "Scores et caractères polygéniques",
    faqs: [
      {
        question: "Qu'est-ce qu'un score polygénique ?",
        answer: `
            Un score polygénique (PGS) est une mesure de la prédisposition génétique qui analyse de nombreux SNP contributifs, au lieu d'un seul. La plupart des traits sont déterminés par de nombreux SNP, et pas seulement un. Comme le PGS examine de nombreux SNP, il s'agit d'une évaluation génétique plus complète et est généralement plus précise qu'une analyse d'un seul SNP.
          `,
      },
      {
        question: "Que signifient les scores ?",
        answer: `
            Dans PureInsight<sup>🅪</sup>, le score reflète le nombre d'allèles à risque qui contribuent au trait. Plus le score est élevé, plus la prédisposition génétique est importante. Par exemple, un score élevé pour la vitamine B12 indique que les besoins en vitamine B12 d'une personne sont plus grands que les besoins d'une personne ayant un score plus faible.
          `,
      },
      {
        question: "Combien de temps faudra-t-il pour recevoir le rapport sur les risques polygéniques ?",
        answer: `
            Le calcul des scores polygéniques ne devrait pas prendre plus d'une heure pour être généré, et une fois le score prêt, une notification par e-mail vous sera envoyée à vous et à votre patient. Si vos scores n'ont pas été générés d'ici là, contactez-nous <a href="/contact-us">ici</a>.
          `,
      },
      {
        question: "Pourquoi des améliorations ont-elles été apportées pour quelques traits sélectionnés ?",
        answer: `
            L'exactitude des scores et des recommandations d'apport dépend de solides études d'association à l'échelle du génome et du développement de modèles mathématiques nécessitant des données de population sur les taux sanguins et des informations sur les apports alimentaires. Ces informations ne sont actuellement pas disponibles pour toutes les caractéristiques. Dans le cadre de nos activités de recherche et de développement, nous continuerons d'évaluer les nouvelles caractéristiques afin de déterminer leur compatibilité avec le PGS et de formuler des recommandations d'admission personnalisées.
          `,
      },
      {
        question: "Comment sont sélectionnés les SNP pour le trait polygénique ?",
        answer: `
            Les SNP contributeurs analysés par le PGS ont été sélectionnés sur la base d’études d’association à l’échelle du génome et d’autres sources de données de population. Les SNP ayant des effets importants et statistiquement significatifs sur les niveaux de nutriments sont inclus dans un score polygénique.
          `,
      },
      {
        question: "Quelle est la précision des scores et des recommandations d'admission ?",
        answer: `
            Les scores polygéniques et les recommandations d'apport personnalisées sont calculés à partir de données de population montrant des corrélations entre les apports nutritionnels, les taux sanguins et les génotypes. Ces calculs sont basés sur la génétique. Les praticiens sont encouragés à ajuster les recommandations d'apport nutritionnel en fonction des laboratoires et d'autres évaluations.
          `,
      },
    ],
  },
  {
    section: "Confidentialité et sécurité",
    faqs: [
      {
        question: "Où puis-je aller pour en savoir plus sur la vie privée de mon patient?",
        answer: `
            Pour plus d’informations sur nos pratiques de confidentialité et de sécurité, veuillez consulter notre <a href="https://www.madewithnestle.ca/privacy-policy?_wrapper_format=html">Politique de confidentialité</a>.
          `,
      },
      {
        question: "Quelles sont les mesures de confidentialité et de sécurité prises pour protéger les données des patients?",
        answer: `
            <p>Nous utilisons les pratiques standard de l’industrie pour stocker vos résultats de tests génétiques et autres informations personnelles que vous nous fournissez dans une base de données sécurisée, qui emploie un certain nombre de mesures de sécurité. Les mesures de sécurité spécifiques utilisées sont basées sur la sensibilité des renseignements personnels recueillis.</p>
            <p>Toutes les connexions vers et depuis notre site Web sont cryptées à l’aide de la technologie Secure Socket Layer (SSL). De plus, les données génétiques brutes des patients sont complètement dépersonnalisées et chiffrées dans notre base de données.</p>
            <p>En plus de protéger les informations des utilisateurs contre les accès non autorisés de personnes extérieures à l’entreprise, nous limitons strictement l’accès à cette base de données depuis l’intérieur de l’entreprise. Seuls les employés qui ont besoin des informations pour effectuer un travail spécifique (par exemple, un représentant du service clientèle) ont accès aux informations personnellement identifiables.</p>
          `,
      },
      {
        question: "PureInsight<sup>🅪</sup> est-il sécurisé et privé?",
        answer: `
            <p>Nous stockons les résultats des tests génétiques des patients sans leur nom ou d’autres informations d’identification communes. Cela signifie qu’aucun affilié extérieur ne peut accéder aux données génétiques de vos patients et que leurs informations personnelles ont été détachées de leur ADN.</p>
            <p>Nous utilisons un logiciel de serveur sécurisé pour chiffrer les informations personnelles (y compris les informations de santé sensibles et les données génétiques), et nous ne travaillons qu’en partenariat avec des entreprises de sécurité qui respectent et s’engagent à respecter nos normes de sécurité. Bien que nous ne puissions pas garantir que la perte, l’utilisation abusive ou l’altération des données ne se produira pas, nous faisons des efforts raisonnables pour empêcher cela.</p>
          `,
      },
      {
        question: "Des informations seront-elles partagées?",
        answer: `
            Seuls vous et votre patient aurez accès à leur fichier de données génétiques. Nous ne partageons pas avec des tiers les données personnelles d’un patient, sauf si la loi l’exige ou avec son consentement explicite.
          `,
      },
      {
        question: "Qui puis-je contacter pour obtenir de l’aide?",
        answer: `
            <ul>
              <li>
                <div class="pg-text">
                  Pour toute question, notre équipe de service Client est disponible par email : <a href="mailto:pureinsightpro@ca.nestle.com">pureinsightpro@ca.nestle.com</a>
                </div>
              </li>
            </ul>
          `,
      },
    ],
  },
];


export const CAPatientFAQData = [
  {
    section: "PureInsight<sup>™</sup> Usage and Registration",
    faqs: [
      {
        question: "What is PureInsight<sup>™</sup>?",
        answer: `
            PureInsight<sup>&trade;</sup> is our new, complementary platform that helps your healthcare
            provider create a deeper, personalized plan of care unique to your health needs.
            Unlike PureGenomics<sup>&reg;</sup>, our PureInsight<sup>&trade;</sup> platform allows your
            healthcare provider to utilize patient questionnaires and validated tools to provide a plan of care.
            With PureInsight<sup>&trade;</sup>, genetic data and lab test data are no longer required but remain optional
            for you to upload.
          `,
      },
      {
        question: "Why is PureGenomics<sup>®</sup> changing to PureInsight<sup>™</sup>?",
        answer: `
            We want our services to align with your needs and our go-forward strategy to support
            and empower your healthcare provider in designing evidence-based, personalized, and a
            scientifically supported plan of care focused on diet, exercise, lifestyle, and supplement
            recommendations that can be shared with you.
          `,
      },
      {
        question: "Will the rebrand introduce a new logo?",
        answer: `
            Yes, the new logo is shown throughout the website – PureInsight<sup>&trade;</sup> Powered by PureGenomics<sup>&reg;</sup>
          `,
      },
      {
        question: "What is changing for me?",
        answer: `
            <p>
              The look and feel of our website will change slightly. Individual patient pages will remain the same with updated descriptions to help emphasize what this platform delivers and the tools available when you sign in to your PureInsight<sup>&trade;</sup> account. The platform also introduces patient questionnaires and validated tools to provide a plan of care. With PureInsight<sup>&trade;</sup>, genetic data and lab test data are no longer required but remain optional for you to upload.
            </p>
            <p>
              If you don’t have access, you will need to speak with your healthcare provider, and they will send you an email invitation with a link to register for an account. Rest assured that you will continue to receive the exceptional services and features that you have become familiar with. This transition will be smooth and effortless, allowing your healthcare provider to utilize our services with even greater efficiency and provide you with a holistic plan of care.
            </p>
          `,
      },
      {
        question: "I have a PureGenomics<sup>®</sup> account. Do I need to create a new one to access PureInsight<sup>™</sup>?",
        answer: `
            No, your credentials from your PureGenomics<sup>&reg;</sup> can be used to log in to access the PureInsight<sup>&trade;</sup> platform.
          `,
      },
      {
        question: "I did not have a PureGenomics<sup>®</sup> account. How do I get access to PureInsight<sup>™</sup>?",
        answer: `
            If you don’t have access, you will need to speak with your healthcare provider, and they will send you an email invitation with a link to register for an account.
          `,
      },
      {
        question: "Is the https://www.puregenomics.ca/ web address staying the same?",
        answer: `
            No, the new web address is <a href="https://pureinsight.pureencapsulationspro.com" target="_blank" rel="noreferrer">pureinsight.pureencapsulationspro.com</a>
          `,
      },
      {
        question: "Is there a specific transition plan I need to be aware of?",
        answer: `
            No, we will gradually transition to the new brand. Access to your patient account is not impacted and you can use new features and tools 24/7.
          `,
      },
      {
        question: "Where can I send any follow-up questions I might have?",
        answer: `
            For any additional questions about the rebranding of PureGenomics<sup>&reg;</sup> to PureInsight<sup>&trade;</sup>,
            please click on <a href="/contact-us">Contact Us</a> and submit your inquiry.
          `,
      },
      {
        question: "What is the cost to use PureInsight<sup>™</sup>?",
        answer: `
            PureInsight<sup>™</sup> is complimentary to use! We believe in supporting better health
            for all, that's why you can benefit from all that PureInsight<sup>™</sup> has to offer at no cost.
          `,
      },
      {
        question: "Why do you offer this complimentary?",
        answer: `
            Our company believes in the advancement of Functional Medicine by providing technology, clinical protocols, and evidence-based products to best support healthcare providers in their personalized care of their patients.
          `,
      },
      {
        question: "I would like to use your service to analyze my raw data. Is this service still available and how do I sign up?",
        answer: `
            <p>
              Patients must be invited by their healthcare provider to create an account. Please reach out to your healthcare provider regarding setting up a free account with us.
            </p>
            <p>
              After your healthcare provider adds you as a patient, you will receive an email from PureInsight<sup>&trade;</sup> on behalf of your provider, to complete your registration. When you log in for the first time, you will be prompted to set up a password and consent to certain key policies and terms of service. You will then have access to your personalized dashboard which will guide you through the tool.
            </p>
          `,
      },
      {
        question: "Which are the supported browsers and devices/platforms? ",
        answer: `
            The PureInsight<sup>&trade;</sup> application is compatible with all devices and browsers.
          `,
      },
      {
        question: "When my healthcare provider added me in as a patient, they received an error message asking them to try another email address. What should I do? ",
        answer: `
            That error message means that the email address is already in use in our system. The email address is a unique identifier and can only be used one time. Since that email has been used previously, you will need to provide another email address to establish an account.
          `,
      },
      {
        question: "I would like to set my family members up with PureInsight<sup>™</sup> accounts. How do I do this?",
        answer: `
            Family members will need to be invited by a participating healthcare provider to set up their own accounts. Each patient must use a different email address to create a PureInsight<sup>™</sup> account. Patients must be must be eighteen (18) years of age or older.
          `,
      },
    ],
  },
  {
    section: "Personalization and Questionnaires",
    faqs: [
      {
        question: "Why does my healthcare provider want me to complete a Multi-Symptom Questionnaire (MSQ)?",
        answer: `
            The MSQ is a short, simple quiz, designed to provide your healthcare provider with a glimpse of your overall health and wellness status. This information can help inform your healthcare provider about your personalized lifestyle and supplement considerations.
          `,
      },
      {
        question: "How do I complete the Multi-Symptom Questionnaire?",
        answer: `
            When you log in to your PureInsight<sup>&trade;</sup> account, you will see the “MULTI-SYMPTOM QUESTIONNAIRE” in your Dashboard.
            Just click “START QUESTIONNAIRE”.
          `,
      },
      {
        question: "What if I don’t yet have my genetic data? Can I go ahead and complete the MSQ?",
        answer: `
            Yes, you can complete the questionnaire MSQ prior to receiving your genetic raw data file. While uploading genetic raw data remains an option, it is no longer required. Once your healthcare provider creates your account, you can log in and complete the questionnaire. When you click “Get Started” it takes you to a page to upload your raw data. Since you are not yet ready for this step, click dashboard, scroll down past data upload, and click “Start Questionnaire.”
          `,
      },
      {
        question: "Will my answers be saved if I need to continue answering the Multi-Symptom Questionnaire at a later time?",
        answer: `
            Yes. It is saved automatically. If you decide to return to your dashboard or exit completely, when you log back in, under the Multi-Symptom Questionnaire section, click on the Continue Questionnaire button, and you can pick up where you left off.
          `,
      },
      {
        question: "Can I edit my responses?",
        answer: `
            You will have the ability to edit your responses before submitting your final answers. You will only have one opportunity to make any necessary changes. If you need to edit any answers beyond this or update your responses in the future, you will need to complete a new questionnaire. You can ask your healthcare provider to send you a new questionnaire.
          `,
      },
      {
        question: "Why does my healthcare provider require additional health questionnaires? Generalized Anxiety Disorder Questionnaire (GAD-7) and Patient Health Questionnaire (PHQ-9)?",
        answer: `
            Based on how the Multi-Symptom Questionnaire is answered, these additional questionnaires may be recommended by your healthcare provider. These additional assessment instruments can help your healthcare provider gain a deeper understanding of your health and wellness status.
          `,
      },
      {
        question: "How does my MSQ score affect me?",
        answer: `
            The score is a measure of the overall frequency of your health and wellness status and will help your healthcare provider track your progress over time.
          `,
      },
    ],
  },
  {
    section: "Genetic Testing, Labs and Upload",
    faqs: [
      {
        question: "What genetic testing labs are compatible with PureInsight<sup>™</sup>?",
        answer: `
            PureInsight<sup>&trade;</sup> only interprets genetic data from  DNALabs, Nordic Labs, 23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup>.  While uploading genetic raw data remains an option, it is no longer required.
          `,
      },
      {
        question: "I noticed that Nordic Labs, 23andMe<sup>®</sup> and Ancestry<sup>®</sup> offer different DNA kits. Is there a particular DNA kit that I should buy if I am interested in using your platform?",
        answer: `
            Any DNA kit sold by either Nordic Labs, 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> is compatible with PureInsight<sup>&trade;</sup>.
          `,
      },
      {
        question: "Can I order Nordic Laboratories, 23andMe<sup>®</sup> or Ancestry<sup>®</sup> test kits through PureInsight<sup>™</sup>?",
        answer: `
            No. You must order the test kits for those genetic testing providers directly through their websites which are linked for your convenience in the genetic upload portion of your dashboard. However, if you choose to use DNA Labs as your genetic testing provider for PureInsight, you must order the test kit through the link provided within the PureInsight dashboard as the results are sent securely and automatically to PureInsight.
          `,
      },
      {
        question: "How do I download my test data from 23andMe<sup>®</sup> or Ancestry<sup>®</sup> and upload this data to PureInsight<sup>™</sup>?",
        answer: `
            <b>Through 23andMe<sup>&reg;</sup>: </b>
            <p>You can access 23andMe<sup>&reg;</sup> through your PureInsight<sup>&trade;</sup> account by logging in and clicking on the 23andMe<sup>&reg;</sup> link. Please note that when you click on the 23andMe<sup>&reg;</sup> link, you will be leaving the PureInsight<sup>&trade;</sup> website and will be transferred to the 23andMe<sup>&reg;</sup> website and be subject to 23andMe<sup>&reg;</sup> website’s privacy policy and terms of service. PureInsight<sup>&trade;</sup> is not responsible for the content and services provided by 23andMe<sup>&reg;</sup>.</p>
            <p>Once you log into 23andMe<sup>&reg;</sup>, directions are provided on this page for you to download your genetic raw data file.</p>
            <p>At the bottom of this page, you need to check a box acknowledging you are downloading sensitive genetic data from a secure server, and then click <b>Submit Request.</b></p>
            <p>23andMe<sup>&reg;</sup> requires a 2-step authentication process. You will receive an email from 23andMe<sup>&reg;</sup> when your raw data download is ready. Click on the email’s call to action at the bottom of the email. This will take you back to your 23andMe<sup>&reg;</sup> account where you can download your raw data .zip file and save it to your computer.</p>
            <p>Lastly, go back into your PureInsight<sup>&trade;</sup> patient dashboard and click <b>Upload Your Data,</b> select your file from the saved location, and click <b>Start Upload.</b></p>
            <p>
              Once your DNA data is uploaded, your healthcare provider will review the results and release your PureInsight<sup>&trade;</sup> patient report with recommendations based on the information that you have provided. Once the report is released by your healthcare provider, you will be able to immediately view it and follow-up with your healthcare provider as necessary. Schedule an appointment to review your PureInsight<sup>&trade;</sup> patient report with your healthcare provider. To read more directions from 23andMe<sup>&reg;</sup> about downloading your data click <a href="https://customercare.23andme.com/hc/en-us/articles/212196868-Accessing-Your-Raw-Genetic-Data" target="_blank" rel="noreferrer">here</a>.
            </p>
  
            <b>Through Ancestry<sup>&reg;</sup>: </b>
            <p>You can access Ancestry<sup>&reg;</sup> through your PureInsight<sup>&trade;</sup> account by logging in and clicking on the Ancestry<sup>&reg;</sup> link. Please note that when you click on the Ancestry<sup>&reg;</sup> link, you will be leaving the PureInsight<sup>&trade;</sup> website and will be transferred to the Ancestry<sup>&reg;</sup> website and be subject to Ancestry<sup>&reg;</sup> website’s privacy policy and terms of service. PureInsight<sup>&trade;</sup> is not responsible for the content and services provided by Ancestry<sup>&reg;</sup>.</p>
            <p>Once logged in to your PureInsight<sup>&trade;</sup> account, you will need to click the Ancestry<sup>&reg;</sup>.ca link to log into your account on Ancestry.ca. Once you log in, directions are provided for you to download your genetic raw data file.</p>
            <p>Ancestry.ca requires a 2-step authentication process. You will receive an email from Ancestry.ca when your raw data download is ready. Click on the email’s call to action <b>Confirm Data Download</b> and save the .zip file to a location on your computer.</p>
            <p>Lastly, go back into your PureInsight<sup>&trade;</sup> patient dashboard and click <b>Upload Your Data,</b> select your file from the saved location, and click <b>Start Upload.</b></p>
            <p>
              Once your DNA data is uploaded, your healthcare provider will review the results and release your PureInsight<sup>&trade;</sup> patient report and their recommendations based on the information that you have provided. Once the report is released by your healthcare provider, you will be able to immediately view it and follow-up with your healthcare provider as necessary. For more download directions from Ancestry.ca click <a href="https://support.ancestry.com/s/article/Downloading-AncestryDNA-Raw-Data" target="_blank" rel="noreferrer">here</a>.
            </p>
          `,
      },
      {
        question: "I received an error message when I tried to upload my genomic data. How do I fix this?",
        answer: `
            <p>There are three reasons an error may occur while uploading your genomic data.</p>
            <ul>
              <li>The data is from a non-compatible testing company. PureInsight<sup>&trade;</sup> is only compatible with 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> test results.</li>
              <li>You are attempting to upload results in a format other than a text or a zip file (like a PDF). Please upload your genomic report in a .txt or .zip file format. This is the common download format from 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>. </li>
              <li>Your data is unreadable. Data may become corrupt and is unintelligible by our system. Please re-download the file from the testing website and try again. When the pop-up window appears where it asks you if you want to Open or Save your data, click Save as opening the file can lead to corruption.</li>
            </ul>
          `,
      },
      {
        question: "Do my genetic results from other genetic testing labs work with PureInsight<sup>™</sup>?",
        answer: `PureInsight<sup>&trade;</sup> is only compatible with 23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup>.`,
      },
      {
        question: "How long after I complete the 23andMe<sup>®</sup> or Ancestry<sup>®</sup> genetic test will I get my results?",
        answer: `
            Both companies state that a period of 6-8 weeks can be expected. When the results are ready, you will be able to log in and download your genetic raw data file.
          `,
      },
      {
        question: "Can my provider upload my raw data file for me?",
        answer: `
            Yes, your provider can upload your raw data file for you. You will need to email them the data file that you received from your testing company. This file will be in the form of a zip or text file, PDFs are not compatible with our system.
          `,
      },
      {
        question: "My healthcare provider said we can enter my blood lab results into PureInsight<sup>™</sup>? What information is this providing?",
        answer: `
            <p>Blood lab data helps your healthcare provider learn more about your unique health and wellness needs. Once your healthcare provider receives your lab work, they will enter the results into PureInsight<sup>&trade;</sup>. The lab upload capability easily displays nutrient status. The nutrient status will help show whether a genetic variant is being expressed and proposes more precise supplement and dosing considerations.</p>
            <p>A healthcare provider cannot diagnose or prescribe medication using the PureInsight<sup>&trade;</sup> services including the nutrigenomics report. The nutrigenomics report provides insight where you may need additional nutritional support. This tells the provider what labs they can order to investigate further.</p>
            <p>PureInsight<sup>&trade;</sup> is a general wellness tool intended to help maintain or encourage a healthy lifestyle. It is not medical advice nor intended to be used to diagnose, treat, cure or prevent any disease or condition.</p>
          `,
      },
      {
        question: "Do I upload my lab results or does my provider?",
        answer: `
            Your healthcare provider uploads the lab results and will review them with you at your next appointment.
          `,
      },
      {
        question: "I can only view my raw genetic data from 23andMe<sup>®</sup> but not download it, what should I do?",
        answer: `
            Unfortunately, we do not have control over the 23andMe<sup>&reg;</sup> system.
            Nevertheless, we maintain ongoing communication with them, and at present,
            they do not have a specific timeframe for resolving the issue.
            We suggest you reach out to 23andMe<sup>&reg;</sup> for information on alternative
            methods to obtain your genetic file so you can upload it in PureInsight<sup>&trade;</sup>
            and get your patient report. Alternatively, our system is also compatible with
            genetic data from Ancestry<sup>&reg;</sup>.
          `,
      },
    ],
  },
  {
    section: "Report",
    faqs: [
      {
        question: "How long does it take to have the genetic data interpreted by PureInsight<sup>™</sup>?",
        answer: `
            Once your data is uploaded, your provider will receive information about your genetic data
            to review. Once the healthcare provider has reviewed according to their clinical discretion,
            they will release the report and their recommendations for your viewing.
            If you haven't already, please schedule a consultation with your healthcare provider
            to review your PureInsight<sup>&trade;</sup> patient report.
          `,
      },
      {
        question: "How will I know if my PureInsight<sup>™</sup> report is ready?",
        answer: `
            You will receive an email notifying you that your healthcare provider has finalized your PureInsight<sup>&trade;</sup> report and it is ready for your review.
          `,
      },
      {
        question: "What does a PureInsight<sup>™</sup> report look like?",
        answer: `
            PureInsight<sup>&trade;</sup> offers two ways to view your report; an interactive report organized by trait category or available as a printable summary report that consolidates all the results into one place.
          `,
      },
      {
        question: "What is the difference between the PureInsight<sup>™</sup> report shared by my healthcare provider and the full DNA report?",
        answer: `
            The PureInsight<sup>&trade;</sup> report shared by your healthcare provider focuses on specific areas of your lifestyle, health, and wellness by providing personalized diet, exercise, lifestyle, and supplement recommendations. The DNA report provides details about your analyzed genetic traits.
          `,
      },
      {
        question: "How do I save my PureInsight<sup>™</sup> patient report to my computer?",
        answer: `
            To save the report to your computer, click on the <b>View and Print Report</b> button at the top of the page. You will see a download button on the next screen.
          `,
      },
      {
        question: "I just received my PureInsight<sup>™</sup> patient report. How do I send it to my doctor?",
        answer: `
            As soon as your data is uploaded, your doctor is automatically notified, and the report is instantly made available for their review. Please contact your healthcare provider to schedule an appointment to review your PureInsight<sup>&trade;</sup> patient report.
          `,
      },
      {
        question: "How does PureInsight<sup>™</sup> deepen personalization?",
        answer: `
            PureInsight<sup>&trade;</sup> allows your health care provider to collect a variety of clinically relevant information from questionnaires, DNA, and blood work to support their ability to clearly identify priority health areas and help you achieve your health and wellness goals. DNA is no longer required but when DNA is provided, you get nutrigenomic insights that help you to better understand how your genetics may influence your health and you receive highly personalized recommendations based on both your questionnaires and genetics results.
          `,
      },
    ],
  },
  {
    section: "Supplement Recommendations",
    faqs: [
      {
        question: "How can I purchase get the supplements recommended by my healthcare provider?",
        answer: `
            Please follow up with your healthcare provider for guidance regarding supplement recommendations and fulfillment.  You can also easily access your healthcare provider’s supplement recommendations via our virtual dispensary. You will be able to shop the recommendations from your healthcare practitioner as well as access the entire Pure Encapsulations product catalog of over 400 supplements.
          `,
      },
    ],
  },
  {
    section: "Nutrigenomics and Traits",
    faqs: [
      {
        question: "What is nutrigenomics? What is nutrigenomics testing? ",
        answer: `
            Nutrigenomics is a branch of science that uses cutting-edge genomics technology to study the link between our genes, nutrition, and health. This information allows us to develop personalized strategies to help patients achieve their health and wellness goals.
          `,
      },
      {
        question: "What are traits?",
        answer: `
            Traits are specific characteristics like eye color, blood type, or the ability to tolerate lactose that are based on an individual's unique genetic variations (SNPs), as well as environmental factors.
          `,
      },
      {
        question: "Which trait categories are supported by PureInsight<sup>™</sup>? ",
        answer: `
            PureInsight<sup>&trade;</sup> reports offer insights into the
            following trait categories:
            <ul>
              <li>Vitamins, minerals & omega-3s</li>
              <li>Detoxification</li>
              <li>Glucose metabolism</li>
              <li>Metabolic & weight management</li>
              <li>Cognitive health & memory</li>
              <li>Immune health</li>
              <li>Energy & fitness</li>
              <li>Cardiovascular health</li>
              <li>Gastrointestinal health</li>
            </ul>
          `,
      },
      {
        question: "What are Single Nucleotide Polymorphisms (SNPs)? ",
        answer: `
            Called SNPs, or 'snips' for short, these are unique parts of your DNA. Some SNPs can affect your health. Some of them can also affect how your body breaks down and uses nutrients.
          `,
      },
      {
        question: "If there are millions of SNPs, why does the report only show a limited number?",
        answer: `
            Most SNPs have no effect on nutritional health and therefore lack
            nutritional relevance. PureInsight<sup>&trade;</sup> includes SNPs
            that are:
            <ol>
              <li>Relevant to health</li>
              <li>Validated by published research</li>
              <li>Responsive to nutritional support</li>
            </ol>
          `,
      },
      {
        question: "Which testing companies’ genetic raw data file provides the most SNPs on your PureInsight<sup>™</sup> report? ",
        answer: `
            23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup> use a
            “genotyping chip” to detect patients’ DNA data. The reference SNP
            cluster IDs, also known as rs numbers, are included on the raw
            data is dependent on which chip was used. These companies commonly
            update their chip versions for a variety of reasons, which can
            impact the raw data available for further analysis. Below is a
            chart that outlines the differences in the most common chip
            versions used in PureInsight<sup>&trade;</sup>. (The “X” indicates
            that the SNP is included in that chip version).
          `,
      },
    ],
  },
  {
    section: "Polygenic Scores and Traits",
    faqs: [
      {
        question: "What is a polygenic score?",
        answer: `
            A polygenic score (PGS) is a measurement of genetic predisposition that analyzes many contributing SNPs, instead of just one. Most traits are determined by many SNPs, not just one. Since PGS looks at many SNPs, it is a more comprehensive genetic assessment and is generally more accurate than single SNP analysis.
          `,
      },
      {
        question: "What do the scores mean?",
        answer: `
            In PureInsight<sup>&trade;</sup>, the score reflects the number of risk alleles that contribute to the trait. The higher the score, the greater the genetic predisposition. For example, a high score for vitamin B<sub>12</sub> indicates that an individual’s B<sub>12</sub> needs are greater than the needs of someone with a lower score.
          `,
      },
      {
        question: "How long should I wait for my Polygenic Scores to be available?",
        answer: `
            Calculating the Polygenic scores should take no more than an hour to get generated, and once the score is ready, as long as your health care practitioner has released your report, an email notification will be sent to both you and your practitioner. If your scores were not generated by then, please contact us <a href="/contact-us">here</a>.
          `,
      },
      {
        question: "Why were enhancements made for just a few select traits? ",
        answer: `
            The accuracy of scores and intake recommendations depends on robust genome-wide association studies and development of mathematical models requiring population data on blood levels and dietary intake information. This information is currently not available for all traits. As part of our ongoing research and development, we will continue to evaluate new traits for compatibility with PGS and personalized intake recommendations.
          `,
      },
      {
        question: "How are the SNPs for the polygenic trait selected? ",
        answer: `
            The contributing SNPs analyzed by the PGS were selected based on genome-wide association studies and other population data sources. SNPs with strong and statistically significant effects on nutrient levels are included in a polygenic score.
          `,
      },
      {
        question: "How accurate are the scores and intake recommendations?",
        answer: `
            The polygenic scores and personalized intake recommendations are calculated from population data showing correlations between nutrient intakes, blood levels and genotypes. These calculations are based on genetics. Practitioners are encouraged to adjust nutrient intake recommendations based on labs and other assessments.
          `,
      },
    ],
  },
  {
    section: "Privacy and Security",
    faqs: [
      {
        question: "Where can I go to learn more about the PureInsight<sup>™</sup> Privacy Policy?",
        answer: `
            PureInsight<sup>&trade;</sup> will not sell, share, or rent a patient's 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> data files to third parties. Nor will we store any genetic data on disc unless it is being processed, and the database itself is encrypted. For more information about our privacy and security practices, please review our <a href="https://www.madewithnestle.ca/privacy-policy?_wrapper_format=html">Privacy Policy</a>.
          `,
      },
      {
        question: "What privacy and security measures are taken to protect my genetic data?",
        answer: `
            <p>We use industry standard practices to store your genetic test results and other Personal Information you provide to us in a secured database, which employs a number of security measures. The specific security measures used are based on the sensitivity of the Personal Information collected.</p>
            <p>All connections to and from our website are encrypted using Secure Socket Layer (SSL) technology. In addition, your genetic raw data is completely de-identified and encrypted in our database encryption to protect sensitive information online, we also do everything in our power to protect user-information offline.</p>
            <p>As well as protecting users' information from unauthorized access from those outside of the company, we strictly limit access to this database from within the company. Only employees who need the information to perform a specific job (for example, a customer service representative) are granted access to personally identifiable information.</p>
          `,
      },
      {
        question: "How secure and private is PureInsight<sup>™</sup>?",
        answer: `
            <p>We store your genetic test results without your name or other common identifying information. This means that no outside affiliate can access your genetic data and that your Personal Information has been unattached from your DNA.</p>
            <p>We use secure server software to encrypt Personal Information (including sensitive health information and genetic data), and we only partner with security companies that meet and commit to our security standards. While we cannot guarantee that loss, misuse, or alteration of data will not occur, we use reasonable efforts to prevent this.</p>
            <p>It is also important for you to guard against unauthorized access to your Personal Information by maintaining strong passwords and protecting against the unauthorized use of your own computer or device. You should not disclose your authentication information to any third party and should immediately notify us of any unauthorized use of your password.</p>
          `,
      },
      {
        question: "Who will have access to my DNA?",
        answer: `
            <p>Only you and your healthcare provider will have access to your genetic data file. We do not share with third parties your name or other common identifying information linked to your genetic data, except as legally required or with your explicit consent. We cannot secure personal data that you release on your own or that you request us to release. If you ask your healthcare provider to incorporate your data into your medical record, for example, under federal regulations the government may access it for law enforcement purposes.</p>
            <p>Please note, should you access the website of 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> by way of links provided on this website, you are leaving PureInsight<sup>&trade;</sup> and will be subject to the privacy and security policies of 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>, as applicable. We are not responsible for the content or services provided by external websites.</p>
          `,
      },
      {
        question: "Who can I contact for assistance?",
        answer: `
            <p>
              For any questions, our Customer Service team is available by email: <a href="mailto:pureinsightpro@ca.nestle.ca">pureinsightpro@ca.nestle.ca</a>
            </p>
          `,
      },
    ],
  }
];

export const FRPatientFAQData = [
  {
    section: "Utilisation et inscription de PureInsight<sup>🅪</sup>",
    faqs: [
      {
        question: "Qu’est-ce que PureInsight<sup>🅪</sup> ?",
        answer: `
            PureInsight<sup>🅪</sup> est notre nouvelle plateforme complémentaire qui aide votre fournisseur de soins de santé à créer un plan de soins personnalisé et approfondi adapté à vos besoins en matière de santé. Contrairement à PureGenomics<sup>®</sup>, notre plateforme PureInsight<sup>🅪</sup> permet à votre fournisseur de soins de santé d’utiliser des questionnaires patients et des outils validés pour fournir un plan de soins. Avec PureInsight<sup>🅪</sup>, les données génétiques et les données de tests en laboratoire ne sont plus nécessaires, mais restent facultatives pour que vous puissiez les télécharger.
          `,
      },
      {
        question: "Pourquoi PureGenomics<sup>®</sup> passe-t-il à PureInsight<sup>🅪</sup> ?",
        answer: `
            Nous voulons que nos services s’alignent sur vos besoins et notre stratégie pour l’avenir pour soutenir et habiliter votre fournisseur de soins de santé à concevoir un plan de soins personnalisé, scientifiquement soutenu et fondé sur l’alimentation, l’exercice, le mode de vie et les recommandations de suppléments qui peuvent être partagées avec vous.
          `,
      },
      {
        question: "La nouvelle image de marque introduira-t-elle un nouveau logo?",
        answer: `
            Oui, le nouveau logo est affiché sur tout le site - PureInsight<sup>🅪</sup> Powered by PureGenomics<sup>®</sup>
          `,
      },
      {
        question: "Qu’est-ce qui change pour moi?",
        answer: `
            <p>
              L’apparence de notre site Web changera légèrement. Les pages individuelles des patients resteront les mêmes avec des descriptions mises à jour pour vous aider à mettre en évidence ce que cette plate-forme offre et les outils disponibles lorsque vous vous connectez à votre compte PureInsight<sup>🅪</sup>. La plateforme introduit également des questionnaires patients et des outils validés pour fournir un plan de soins. Avec PureInsight<sup>🅪</sup>, les données génétiques et les données de tests en laboratoire ne sont plus nécessaires, mais restent facultatives pour que vous puissiez les télécharger.
            </p>
            <p>
              Si vous n’y avez pas accès, vous devrez parler à votre fournisseur de soins de santé, qui vous enverra une invitation par courriel avec un lien pour vous inscrire à un compte. Soyez assuré que vous continuerez à bénéficier des services et fonctionnalités exceptionnels que vous avez appris à connaître. Cette transition se fera en douceur et sans effort, ce qui permettra à votre fournisseur de soins de santé d’utiliser nos services avec encore plus d’efficacité et de vous fournir un plan de soins global.
            </p>
          `,
      },
      {
        question: "J’ai un compte PureGenomics<sup>®</sup>. Dois-je en créer un nouveau pour accéder à PureInsight<sup>🅪</sup> ?",
        answer: `
            Non, vos informations d’identification de votre PureGenomics<sup>®</sup> peuvent être utilisées pour vous connecter à la plateforme PureInsight<sup>🅪</sup>.
          `,
      },
      {
        question: "Je n’avais pas de compte PureGenomics<sup>®</sup>. Comment puis-je accéder à PureInsight<sup>🅪</sup> ?",
        answer: `
            Si vous n’y avez pas accès, vous devrez parler à votre fournisseur de soins de santé, qui vous enverra une invitation par courriel avec un lien pour vous inscrire à un compte.
          `,
      },
      {
        question: "L’adresse Web https://www.puregenomics.ca reste-t-elle la même?",
        answer: `
            Non, la nouvelle adresse Web est <a href="https://pureinsightpro.ca" target="_blank" rel="noreferrer">pureinsightpro.ca</a>
          `,
      },
      {
        question: "Y a-t-il un plan de transition précis dont je dois être au courant?",
        answer: `
            Non, nous allons progressivement passer à la nouvelle marque. L’accès à votre compte patient n’est pas affecté et vous pouvez utiliser de nouvelles fonctionnalités et outils 24 heures sur 24 et 7 jours sur 7.
          `,
      },
      {
        question: "Où puis-je envoyer mes questions complémentaires?",
        answer: `
            Pour toute question supplémentaire sur le changement de marque de PureGenomics<sup>®</sup> à PureInsight<sup>🅪</sup>, veuillez cliquer sur <a href="/contact-us">Contactez-nous</a> et soumettre votre demande.
          `,
      },
      {
        question: "Quel est le coût d’utilisation de PureInsight<sup>🅪</sup> ?",
        answer: `
            PureInsight<sup>🅪</sup> est gratuit! Nous croyons qu’il faut favoriser une meilleure santé pour tous, c’est pourquoi vous pouvez profiter gratuitement de tout ce que PureInsight<sup>🅪</sup> a à offrir.
          `,
      },
      {
        question: "Pourquoi offrez-vous ce service gratuit?",
        answer: `
            Notre entreprise croit en l’avancement de la médecine fonctionnelle en fournissant la technologie, les protocoles cliniques et les produits fondés sur des preuves pour soutenir au mieux les fournisseurs de soins de santé dans leurs soins personnalisés de leurs patients.
          `,
      },
      {
        question: "J’aimerais utiliser votre service pour analyser mes données brutes. Ce service est-il toujours disponible et comment m’inscrire?",
        answer: `
            <p>
              Les patients doivent être invités par leur fournisseur de soins de santé à créer un compte. Veuillez contacter votre prestataire de soins de santé pour créer un compte gratuit chez nous.
            </p>
            <p>
              Une fois que votre fournisseur de soins de santé vous aura ajouté en tant que patient, vous recevrez un courriel de PureInsight<sup>🅪</sup> au nom de votre fournisseur, pour compléter votre inscription. Lorsque vous vous connectez pour la première fois, vous êtes invité à définir un mot de passe et à accepter certaines politiques et conditions d’utilisation clés. Vous aurez alors accès à votre tableau de bord personnalisé qui vous guidera à travers l’outil.
            </p>
          `,
      },
      {
        question: "Quels sont les navigateurs et périphériques/plates-formes pris en charge?",
        answer: `
            L’application PureInsight<sup>🅪</sup> est compatible avec tous les appareils et navigateurs.
          `,
      },
      {
        question: "Lorsque mon fournisseur de soins de santé m’a ajouté en tant que patient, il a reçu un message d’erreur lui demandant d’essayer une autre adresse e-mail. Que dois-je faire?",
        answer: `
            Ce message d’erreur signifie que l’adresse e-mail est déjà utilisée dans notre système. L’adresse e-mail est un identifiant unique qui ne peut être utilisé qu’une seule fois. Comme cet e-mail a déjà été utilisé, vous devrez fournir une autre adresse e-mail pour ouvrir un compte.
          `,
      },
      {
        question: "J’aimerais créer des comptes PureInsight<sup>🅪</sup> pour les membres de ma famille. Comment je fais ça?",
        answer: `
            Les membres de la famille devront être invités par un fournisseur de soins de santé participant à ouvrir leur propre compte. Chaque patient doit utiliser une adresse e-mail différente pour créer un compte PureInsight<sup>🅪</sup>. Les patients doivent être âgés d’au moins 18 ans.
          `,
      },
    ],
  },
  {
    section: "Personnalisation et questionnaires",
    faqs: [
      {
        question: "Pourquoi mon fournisseur de soins de santé veut-il que je remplisse un questionnaire multisymptomatique (MSQ) ?",
        answer: `
            Le MSQ est un questionnaire court et simple conçu pour donner à votre professionnel de la santé un aperçu de votre état de santé général. Ces informations peuvent aider à informer votre fournisseur de soins de santé sur votre style de vie personnalisé et les considérations de supplément.
          `,
      },
      {
        question: "Comment remplir le questionnaire multi-symptômes?",
        answer: `
            Lorsque vous vous connectez à votre compte PureInsight<sup>🅪</sup>, le "QUESTIONNAIRE MULTI-SYMPTÔMES" s’affiche dans votre tableau de bord. Cliquez simplement sur "START QUESTIONNAIRE".
          `,
      },
      {
        question: "Et si je n’ai pas encore mes données génétiques? Puis-je aller de l’avant et terminer le MSQ?",
        answer: `
            Oui, vous pouvez remplir le questionnaire MSQ avant de recevoir votre fichier de données génétiques brutes. Bien que le téléchargement de données génétiques brutes demeure une option, il n’est plus nécessaire. Une fois que votre fournisseur de soins de santé a créé votre compte, vous pouvez vous connecter et remplir le questionnaire. Lorsque vous cliquez sur "Get Started", vous accédez à une page pour télécharger vos données brutes. Comme vous n’êtes pas encore prêt pour cette étape, cliquez sur Tableau de bord, faites défiler les données téléchargées et cliquez sur "Démarrer le questionnaire".
          `,
      },
      {
        question: "Mes réponses seront-elles sauvegardées si je dois continuer à répondre au questionnaire multi-symptômes ultérieurement?",
        answer: `
            Oui. Il est enregistré automatiquement. Si vous décidez de revenir à votre tableau de bord ou de quitter complètement, lorsque vous vous reconnecterez, sous la section Questionnaire à symptômes multiples, cliquez sur le bouton Continuer le questionnaire, et vous pourrez reprendre là où vous vous étiez arrêté.
          `,
      },
      {
        question: "Puis-je modifier mes réponses?",
        answer: `
            Vous aurez la possibilité de modifier vos réponses avant de soumettre vos réponses finales. Vous n’aurez qu’une seule occasion d’apporter les modifications nécessaires. Si vous devez modifier des réponses au-delà de cette date ou mettre à jour vos réponses ultérieurement, vous devrez remplir un nouveau questionnaire. Vous pouvez demander à votre professionnel de santé de vous envoyer un nouveau questionnaire.
          `,
      },
      {
        question: "Pourquoi mon fournisseur de soins de santé a-t-il besoin de questionnaires de santé supplémentaires? Questionnaire sur les troubles anxieux généralisés (GAD-7) et Questionnaire sur la santé des patients (PHQ-9) ?",
        answer: `
            En fonction de la réponse au questionnaire multisymptomatique, ces questionnaires supplémentaires peuvent être recommandés par votre professionnel de santé. Ces instruments d’évaluation supplémentaires peuvent aider votre fournisseur de soins de santé à mieux comprendre votre état de santé et de bien-être.
          `,
      },
      {
        question: "Comment mon score MSQ m’affecte-t-il?",
        answer: `
            Le score est une mesure de la fréquence globale de votre état de santé et de bien-être et aidera votre fournisseur de soins de santé à suivre vos progrès au fil du temps.
          `,
      },
    ],
  },
  {
    section: "Tests génétiques, laboratoires et téléchargement",
    faqs: [
      {
        question: "Quels laboratoires de tests génétiques sont compatibles avec PureInsight<sup>🅪</sup>?",
        answer: `
            PureInsight<sup>🅪</sup> interprète uniquement les données génétiques de DNALabs, Nordic Labs, 23andMe<sup>®</sup> et Ancestry<sup>®</sup>. Bien que le téléchargement de données génétiques brutes demeure une option, il n’est plus nécessaire.
          `,
      },
      {
        question: "J’ai remarqué que Nordic Labs, 23andMe<sup>®</sup> et Ancestry<sup>®</sup> offrent différents kits d’ADN. Y a-t-il une trousse d’ADN particulière que je devrais acheter si je suis intéressé à utiliser votre plateforme?",
        answer: `
            Tout kit ADN vendu par Nordic Labs, 23andMe<sup>®</sup> ou Ancestry<sup>®</sup> est compatible avec PureInsight<sup>🅪</sup>.
          `,
      },
      {
        question: "Puis-je commander des kits de test Nordic Labs, 23andMe<sup>®</sup> ou Ancestry<sup>®</sup> via PureInsight<sup>🅪</sup> ?",
        answer: `
            Non. Vous devez commander les trousses directement par l’intermédiaire de <a href="https://dna.nordicvms.com" target="_blank" rel="noreferrer">dna.nordicvms.com</a>, <a href="https://www.23andme.com" target="_blank" rel="noreferrer">23andMe.com</a> ou <a href="https://www.ancestry.com" target="_blank" rel="noreferrer">Ancestry.com</a> ou d’autres magasins de détail autorisés. Veuillez noter que Pure Encapsulations® n’est ni affilié ni approuvé par Nordic Labs, 23andMe<sup>®</sup> ou Ancestry<sup>®</sup>.
          `,
      },
      {
        question: "Comment puis-je télécharger mes données de test de Nordic Labs, 23andMe<sup>®</sup> ou Ancestry<sup>®</sup>, et comment puis-je télécharger ces données dans PureInsight<sup>🅪</sup> ?",
        answer: `
            <b>Jusqu’à 23andMe<sup>®</sup> :</b>
            <p>
              Vous pouvez accéder à 23andMe<sup>®</sup> via votre compte PureInsight<sup>🅪</sup> en vous connectant et en cliquant sur le lien 23andMe<sup>®</sup>. Veuillez noter que lorsque vous cliquerez sur le lien 23andMe<sup>®</sup>, vous quitterez le site Web PureInsight<sup>🅪</sup> et serez transféré sur le site Web 23andMe<sup>®</sup> et soumis à la politique de confidentialité et aux conditions d’utilisation du site Web 23andMe<sup>®</sup>. PureInsight<sup>🅪</sup> n’est pas responsable du contenu et des services fournis par 23andMe<sup>®</sup>.
            </p>
            <p>
              Une fois que vous vous connectez à 23andMe<sup>®</sup>, vous trouverez des instructions sur cette page pour télécharger votre fichier de données génétiques brutes.
            </p>
            <p>
              Au bas de cette page, vous devez cocher une case confirmant que vous téléchargez des données génétiques sensibles à partir d’un serveur sécurisé, puis cliquer sur "Soumettre la demande".
            </p>
            <p>
              23andMe<sup>®</sup> nécessite un processus d’authentification en 2 étapes. Vous recevrez un e-mail de 23andMe<sup>®</sup> lorsque votre téléchargement de données brutes sera prêt. Cliquez sur l’appel à l’action de l’e-mail en bas de l’e-mail. Cela vous ramènera à votre compte 23andMe<sup>®</sup> où vous pourrez télécharger votre fichier.zip de données brutes et l’enregistrer sur votre ordinateur.
            </p>
            <p>
              Enfin, revenez dans votre tableau de bord patient PureInsight<sup>🅪</sup> et cliquez sur "Télécharger vos données", sélectionnez votre fichier à partir de l’emplacement enregistré, puis cliquez sur "Démarrer le téléchargement".
            </p>
            <p>
              Une fois vos données génétiques téléchargées, votre fournisseur de soins de santé examinera les résultats et publiera votre rapport PureInsight<sup>🅪</sup> contenant des recommandations fondées sur les renseignements que vous avez fournis. Une fois le rapport publié par votre fournisseur de soins de santé, vous pourrez le consulter immédiatement et faire un suivi auprès de votre fournisseur de soins de santé au besoin. Prenez rendez-vous pour examiner votre rapport patient PureInsight<sup>🅪</sup> avec votre fournisseur de soins de santé. Pour lire plus d’instructions de 23andMe<sup>®</sup> sur le téléchargement de vos données, <a href="https://customercare.23andme.com/hc/en-us/articles/212196868-Accessing-Your-Raw-Genetic-Data" target="_blank" rel="noreferrer">cliquez ici</a>.
            </p>
  
            <b>Grâce à Ancestry<sup>®</sup> :</b>
            <p>
              Vous pouvez accéder à Ancestry<sup>®</sup> via votre compte PureInsight<sup>🅪</sup> en vous connectant et en cliquant sur le lien Ancestry<sup>®</sup>. Veuillez noter que lorsque vous cliquerez sur le lien Ancestry<sup>®</sup>, vous quitterez le site Web PureInsight<sup>🅪</sup> et serez transféré sur le site Web Ancestry<sup>®</sup> et soumis à la politique de confidentialité et aux conditions d’utilisation du site Web Ancestry<sup>®</sup>. PureInsight<sup>🅪</sup> n’est pas responsable du contenu et des services fournis par Ancestry<sup>®</sup>.
            </p>
            <p>
              Une fois connecté à votre compte PureInsight<sup>🅪</sup>, vous devrez cliquer sur le lien Ancestry<sup>®</sup>.com pour vous connecter à votre compte sur Ancestry.com. Une fois connecté, des instructions vous sont fournies pour télécharger votre fichier de données génétiques brutes.
            </p>
            <p>
              Ancestry.com nécessite un processus d’authentification en 2 étapes. Vous recevrez un e-mail de Ancestry.com lorsque votre téléchargement de données brutes sera prêt. Cliquez sur l’appel à l’action de l’e-mail <b>Confirmer le téléchargement des données</b> et enregistrez le fichier.zip à un emplacement sur votre ordinateur.
            </p>
            <p>
              Enfin, retournez dans votre tableau de bord patient PureInsight<sup>🅪</sup> et cliquez sur "Télécharger vos données", sélectionnez votre fichier à partir de l’emplacement enregistré, puis cliquez sur "Démarrer le téléchargement".
            </p>
            <p>
              Une fois vos données génétiques téléchargées, votre fournisseur de soins de santé examinera les résultats et publiera votre rapport patient PureInsight<sup>🅪</sup> et ses recommandations en fonction des renseignements que vous avez fournis. Une fois le rapport publié par votre fournisseur de soins de santé, vous pourrez le consulter immédiatement et faire un suivi auprès de votre fournisseur de soins de santé au besoin. Pour plus d’instructions de téléchargement sur Ancestry.com, <a href="https://support.ancestry.com/s/article/Downloading-AncestryDNA-Raw-Data" target="_blank" rel="noreferrer">cliquez ici</a>.
            </p>
          `,
      },
      {
        question: "J’ai reçu un message d’erreur quand j’ai essayé de télécharger mes données génomiques. Comment je peux arranger ça?",
        answer: `
            <p>Il y a trois raisons pour lesquelles une erreur peut se produire lors du téléchargement de vos données génomiques.</p>
            <ul>
              <li>Les données proviennent d’une société de test non compatible. PureInsight<sup>🅪</sup> est uniquement compatible avec les résultats des tests 23andMe<sup>®</sup> ou Ancestry<sup>®</sup>.</li>
              <li>Vous tentez de télécharger des résultats dans un format autre qu’un texte ou un fichier zip (comme un fichier PDF). Veuillez télécharger votre rapport génomique au format .txt ou .zip. Il s’agit du format de téléchargement courant de 23andMe<sup>®</sup> ou Ancestry<sup>®</sup>.</li>
              <li>Vos données sont illisibles. Les données peuvent devenir corrompues et sont inintelligibles par notre système. Veuillez télécharger à nouveau le fichier à partir du site Web de test et réessayer. Lorsque la fenêtre contextuelle s’affiche et vous demande si vous souhaitez ouvrir ou enregistrer vos données, cliquez sur Enregistrer sous l’ouverture du fichier peut entraîner une corruption.</li>
            </ul>
          `,
      },
      {
        question: "Est-ce que mes résultats génétiques d’autres laboratoires de tests génétiques fonctionnent avec PureInsight<sup>🅪</sup> ?",
        answer: `
            PureInsight<sup>🅪</sup> est uniquement compatible avec Nordic Labs, 23andMe<sup>®</sup> et Ancestry<sup>®</sup>.
          `,
      },
      {
        question: "Combien de temps après avoir terminé le test génétique Nordic Lab, 23andMe<sup>®</sup> ou Ancestry<sup>®</sup> obtiendrai-je mes résultats?",
        answer: `
            Les deux sociétés déclarent qu’une période de 6 à 8 semaines peut être prévue. Lorsque les résultats seront prêts, vous pourrez vous connecter et télécharger votre fichier de données génétiques brutes.
          `,
      },
      {
        question: "Mon fournisseur peut-il télécharger mon fichier de données brutes pour moi?",
        answer: `
            Oui, votre fournisseur peut télécharger votre fichier de données brutes pour vous. Vous devrez leur envoyer par e-mail le fichier de données que vous avez reçu de votre société de test. Ce fichier sera sous la forme d’un fichier zip ou texte, les PDF ne sont pas compatibles avec notre système.
          `,
      },
      {
        question: "Mon fournisseur de soins de santé m’a dit que nous pouvions entrer les résultats de mon laboratoire sanguin dans PureInsight<sup>🅪</sup> ? Quelles informations cela fournit-il?",
        answer: `
            <p>
              Les données de laboratoire sur le sang aident votre fournisseur de soins de santé à en apprendre davantage sur vos besoins uniques en matière de santé et de bien-être. Une fois que votre fournisseur de soins de santé aura reçu votre travail de laboratoire, il entrera les résultats dans PureInsight<sup>🅪</sup>. La fonction de chargement en laboratoire affiche facilement l’état des nutriments. L’état nutritionnel permettra de déterminer si une variante génétique est exprimée et de proposer des considérations plus précises en matière de supplément et de dosage.
            </p>
            <p>
              Un professionnel de la santé ne peut pas diagnostiquer ou prescrire de médicaments à l’aide des services PureInsight<sup>🅪</sup>, y compris le rapport nutrigénomique. Le rapport nutrigénomique fournit des informations sur les domaines dans lesquels vous pourriez avoir besoin d’un soutien nutritionnel supplémentaire. Cela indique au fournisseur les laboratoires qu’il peut commander pour approfondir ses recherches.
            </p>
            <p>
              PureInsight<sup>🅪</sup> est un outil de bien-être général conçu pour aider à maintenir ou à encourager un mode de vie sain. Il ne s’agit pas d’un avis médical et n’est pas destiné à être utilisé pour diagnostiquer, traiter, guérir ou prévenir une maladie ou un état.
            </p>
          `,
      },
      {
        question: "Est-ce que je télécharge mes résultats de laboratoire ou mon fournisseur?",
        answer: `
            Votre fournisseur de soins de santé télécharge les résultats de laboratoire et les examinera avec vous lors de votre prochain rendez-vous.
          `,
      },
      {
        question: "Je ne peux voir que mes données génétiques brutes de 23andMe<sup>®</sup> mais pas les télécharger, que dois-je faire?",
        answer: `
            Malheureusement, nous n’avons aucun contrôle sur le système 23andMe<sup>®</sup>. Néanmoins, nous maintenons une communication continue avec eux et, à l’heure actuelle, ils n’ont pas de délai précis pour régler le problème. Nous vous suggérons de contacter 23andMe<sup>®</sup> pour obtenir des informations sur les méthodes alternatives pour obtenir votre fichier génétique afin de pouvoir le télécharger dans PureInsight<sup>🅪</sup> et obtenir votre rapport patient. Notre système est également compatible avec les données génétiques d’Ancestry<sup>®</sup>.
          `,
      },
    ],
  },
  {
    section: "Rapport",
    faqs: [
      {
        question: "Combien de temps faut-il pour que les données génétiques soient interprétées par PureInsight<sup>🅪</sup> ?",
        answer: `
            Une fois vos données téléchargées, votre fournisseur recevra des informations sur vos données génétiques à examiner. Une fois que le prestataire de soins de santé aura examiné le rapport selon son jugement clinique, il publiera le rapport et ses recommandations pour votre consultation. Si vous ne l’avez pas encore fait, veuillez planifier une consultation avec votre professionnel de la santé pour examiner votre rapport patient PureInsight<sup>🅪</sup>.
          `,
      },
      {
        question: "Comment puis-je savoir si mon rapport PureInsight<sup>🅪</sup> est prêt?",
        answer: `
            Vous recevrez un courriel vous informant que votre fournisseur de soins de santé a finalisé votre rapport PureInsight<sup>🅪</sup> et qu’il est prêt à être examiné.
          `,
      },
      {
        question: "À quoi ressemble un rapport PureInsight<sup>🅪</sup> ?",
        answer: `
            PureInsight<sup>🅪</sup> offre deux façons d’afficher votre rapport : un rapport interactif organisé par catégorie de trait ou disponible sous la forme d’un rapport récapitulatif imprimable qui regroupe tous les résultats en un seul endroit.
          `,
      },
      {
        question: "Quelle est la différence entre le rapport PureInsight<sup>🅪</sup> partagé par mon fournisseur de soins de santé et le rapport complet sur l’ADN?",
        answer: `
            Le rapport PureInsight<sup>🅪</sup> partagé par votre fournisseur de soins de santé se concentre sur des aspects spécifiques de votre mode de vie, de votre santé et de votre bien-être en fournissant un régime alimentaire, de l’exercice, un mode de vie et des recommandations de suppléments personnalisés. Le rapport ADN fournit des détails sur vos traits génétiques analysés.
          `,
      },
      {
        question: "Comment enregistrer mon rapport patient PureInsight<sup>🅪</sup> sur mon ordinateur?",
        answer: `
            Pour enregistrer le rapport sur votre ordinateur, cliquez sur le bouton <b>Afficher et imprimer le rapport</b> en haut de la page. Vous verrez un bouton de téléchargement sur l’écran suivant.
          `,
      },
      {
        question: "Je viens de recevoir mon rapport patient PureInsight<sup>🅪</sup>. Comment l’envoyer à mon médecin?",
        answer: `
            Dès que vos données sont téléchargées, votre médecin en est automatiquement informé et le rapport est immédiatement mis à sa disposition pour examen. Veuillez contacter votre professionnel de santé pour prendre rendez-vous afin d’examiner votre rapport patient PureInsight<sup>🅪</sup>.
          `,
      },
      {
        question: "Comment PureInsight<sup>🅪</sup> renforce-t-il la personnalisation?",
        answer: `
            PureInsight<sup>🅪</sup> permet à votre fournisseur de soins de santé de recueillir une variété d’informations cliniquement pertinentes à partir de questionnaires, d’analyses génétiques et d’analyses sanguines pour appuyer sa capacité à identifier clairement les domaines de santé prioritaires et à vous aider à atteindre vos objectifs de santé et de bien-être. L’ADN n’est plus nécessaire, mais lorsque l’ADN est fourni, vous obtenez des informations nutrigénomiques qui vous aident à mieux comprendre comment votre génétique peut influencer votre santé et vous recevez des recommandations hautement personnalisées basées sur vos questionnaires et les résultats génétiques.
          `,
      },
    ],
  },
  {
    section: "Recommandations de supplément",
    faqs: [
      {
        question: "Comment puis-je acheter obtenir les suppléments recommandés par mon fournisseur de soins de santé?",
        answer: `
            Veuillez consulter votre professionnel de la santé pour obtenir des conseils sur les recommandations et l’exécution des suppléments. Vous pouvez également acheter les recommandations de votre fournisseur de soins de santé via l’un de nos partenaires de vente au détail trouvés sur <a href="https://www.pureencapsulations.ca/en/where-buy" target="_blank" rel="noreferrer">https://www.pureencapsulations.ca/en/where-buy</a>. Vous serez en mesure de consulter les recommandations de votre professionnel de la santé et d’accéder à l’ensemble du catalogue de produits Pure Encapsulations de plus de 120 suppléments.
          `,
      },
    ],
  },
  {
    section: "Nutrigénomique et caractères",
    faqs: [
      {
        question: "Qu’est-ce que la nutrigénomique? Qu’est-ce que le test nutrigénomique?",
        answer: `
            La nutrigénomique est une branche de la science qui utilise la technologie de pointe de la génomique pour étudier le lien entre nos gènes, notre nutrition et notre santé. Cette information nous permet d’élaborer des stratégies personnalisées pour aider les patients à atteindre leurs objectifs de santé et de bien-être.
          `,
      },
      {
        question: "Que sont les traits?",
        answer: `
            Les traits sont des caractéristiques spécifiques comme la couleur des yeux, le groupe sanguin ou la capacité à tolérer le lactose qui sont basées sur les variations génétiques uniques d’une personne, ainsi que des facteurs environnementaux.
          `,
      },
      {
        question: "Quelles catégories de caractéristiques sont prises en charge par PureInsight<sup>🅪</sup> ?",
        answer: `
            Les rapports PureInsight<sup>🅪</sup> offrent des informations sur les catégories de caractéristiques suivantes :
            <ul>
              <li>Vitamines, minéraux et oméga-3</li>
              <li>Désintoxication</li>
              <li>Métabolisme du glucose</li>
              <li>Métabolisme et gestion du poids</li>
              <li>Santé cognitive et mémoire</li>
              <li>Santé immunitaire</li>
              <li>Énergie et forme physique</li>
              <li>Santé cardiovasculaire</li>
              <li>Santé gastro-intestinale</li>
            </ul>
          `,
      },
      {
        question: "Que sont les polymorphismes mononucléotidiques (SNP) ?",
        answer: `
            Appelés SNP, ou "snips" en abrégé, ce sont des parties uniques de votre ADN. Certains SNP peuvent affecter votre santé. Certains d’entre eux peuvent également affecter la façon dont votre organisme se décompose et utilise les nutriments.
          `,
      },
      {
        question: "S’il y a des millions de SNP, pourquoi le rapport n’en montre-t-il qu’un nombre limité?",
        answer: `
            La plupart des SNP n’ont aucun effet sur la santé nutritionnelle et manquent donc de pertinence nutritionnelle. PureInsight<sup>🅪</sup> inclut des SNP qui sont :
            <ol>
              <li>Pertinent pour la santé</li>
              <li>Validée par une recherche publiée</li>
              <li>Réactivité à l’assistance nutritionnelle</li>
            </ol>
          `,
      },
      {
        question: "Quels sont les fichiers de données génétiques brutes des sociétés de test qui fournissent le plus de SNP sur votre rapport PureInsight<sup>🅪</sup> ?",
        answer: `
            23andMe<sup>®</sup> et Ancestry<sup>®</sup> utilisent une "puce de génotypage" pour détecter les données ADN des patients. Les ID de cluster SNP de référence, également appelés numéros rs, sont inclus dans les données brutes en fonction de la puce utilisée. Ces entreprises mettent couramment à jour leurs versions de puces pour diverses raisons, ce qui peut avoir une incidence sur les données brutes disponibles pour une analyse plus approfondie. Vous trouverez ci-dessous un graphique qui présente les différences entre les versions de puce les plus courantes utilisées dans PureInsight<sup>🅪</sup>. (Le "X" indique que le SNP est inclus dans cette version de puce).
            
            <table>
              <thead>
                <tr>
                  <th>SNP testé</th>
                  <th>23andMe</th>
                  <th>Ancestry</th>
                  <th>Version 4</th>
                  <th>Version 5</th>
                  <th>Version 2</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>EDN1 (rs5370)</td>
                  <td>X</td>
                  <td></td>
                  <td></td>
                  <td>X</td>
                  <td></td>
                </tr>
                <tr>
                  <td>GATA3 (rs4143094)</td>
                  <td></td>
                  <td>X</td>
                  <td>X</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>AOC1 (rs10156191)</td>
                  <td>X</td>
                  <td></td>
                  <td></td>
                  <td>X</td>
                  <td></td>
                </tr>
                <tr>
                  <td>BCMO1 (rs12934922)</td>
                  <td>X</td>
                  <td></td>
                  <td>X</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>TCN2 (rs1801198)</td>
                  <td>X</td>
                  <td></td>
                  <td></td>
                  <td>X</td>
                  <td></td>
                </tr>
                <tr>
                  <td>SLC23A1 (rs33972313)</td>
                  <td></td>
                  <td>X</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>DHCR7 (rs12785878)</td>
                  <td></td>
                  <td>X</td>
                  <td></td>
                  <td>X</td>
                  <td></td>
                </tr>
                <tr>
                  <td>CYP2R1 (rs10741657)</td>
                  <td></td>
                  <td>X</td>
                  <td></td>
                  <td>X</td>
                  <td></td>
                </tr>
                <tr>
                  <td>GPx1P1 (rs1050450)</td>
                  <td>X</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>DRD2 (rs6277)</td>
                  <td>X</td>
                  <td>X</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          `,
      },
    ],
  },
  {
    section: "Scores et caractères polygéniques",
    faqs: [
      {
        question: "Qu’est-ce qu’un score polygénique?",
        answer: `
            Un score polygénique (PGS) est une mesure de la prédisposition génétique qui analyse de nombreux SNP contributifs, au lieu d’un seul. La plupart des traits sont déterminés par de nombreux SNP, et pas seulement un. Comme le PGS examine de nombreux SNP, il s’agit d’une évaluation génétique plus complète et est généralement plus précise qu’une analyse d’un seul SNP.
          `,
      },
      {
        question: "Que signifient les scores?",
        answer: `
            Dans PureInsight<sup>🅪</sup>, le score reflète le nombre d’allèles à risque qui contribuent au trait. Plus le score est élevé, plus la prédisposition génétique est importante. Par exemple, un score élevé pour la vitamine B<sub>12</sub> indique que les besoins en vitamine B<sub>12</sub> d’une personne sont plus grands que les besoins d’une personne ayant un score plus faible.
          `,
      },
      {
        question: "Combien de temps dois-je attendre pour que mes scores polygéniques soient disponibles?",
        answer: `
            Le calcul des scores polygéniques ne devrait pas prendre plus d’une heure pour être généré, et une fois le score prêt, tant que votre professionnel de la santé a publié votre rapport, une notification par e-mail vous sera envoyée à vous et à votre professionnel de la santé. Si vos scores n’ont pas été générés d’ici là, contactez-nous <a href="/contact-us">ici</a>.
          `,
      },
      {
        question: "Pourquoi des améliorations ont-elles été apportées pour quelques traits sélectionnés?",
        answer: `
            L’exactitude des scores et des recommandations d’apport dépend de solides études d’association à l’échelle du génome et du développement de modèles mathématiques nécessitant des données de population sur les taux sanguins et des informations sur les apports alimentaires. Ces informations ne sont actuellement pas disponibles pour toutes les caractéristiques. Dans le cadre de nos activités de recherche et de développement, nous continuerons d’évaluer les nouvelles caractéristiques afin de déterminer leur compatibilité avec le PGS et de formuler des recommandations d’admission personnalisées.
          `,
      },
      {
        question: "Comment sont sélectionnés les SNP pour le trait polygénique?",
        answer: `
            Les SNP contributeurs analysés par le PGS ont été sélectionnés sur la base d’études d’association à l’échelle du génome et d’autres sources de données de population. Les SNP ayant des effets importants et statistiquement significatifs sur les niveaux de nutriments sont inclus dans un score polygénique.
          `,
      },
      {
        question: "Quelle est la précision des scores et des recommandations d’admission?",
        answer: `
            Les scores polygéniques et les recommandations d’apport personnalisées sont calculés à partir de données de population montrant des corrélations entre les apports nutritionnels, les taux sanguins et les génotypes. Ces calculs sont basés sur la génétique. Les praticiens sont encouragés à ajuster les recommandations d’apport nutritionnel en fonction des laboratoires et d’autres évaluations.
          `,
      },
    ],
  },
  {
    section: "Confidentialité et sécurité",
    faqs: [
      {
        question: "Où puis-je me rendre pour en savoir plus sur la politique de confidentialité de PureInsight<sup>🅪</sup> ?",
        answer: `
            PureInsight<sup>🅪</sup> ne vendra, ne partagera ni ne louera les fichiers de données Nordic Labs, 23andMe<sup>®</sup> ou Ancestry<sup>®</sup> d’un patient à des tiers. Nous ne stockerons pas non plus de données génétiques sur disque à moins qu’elles ne soient traitées et que la base de données elle-même ne soit cryptée. Pour plus d’informations sur nos pratiques de confidentialité et de sécurité, veuillez consulter notre <a href="https://www.madewithnestle.ca/privacy-policy?_wrapper_format=html">Politique de confidentialité</a>.
          `,
      },
      {
        question: "Quelles mesures de confidentialité et de sécurité sont prises pour protéger mes données génétiques?",
        answer: `
            <p>Nous utilisons les pratiques standard de l’industrie pour stocker vos résultats de tests génétiques et autres informations personnelles que vous nous fournissez dans une base de données sécurisée, qui emploie un certain nombre de mesures de sécurité. Les mesures de sécurité spécifiques utilisées sont basées sur la sensibilité des renseignements personnels recueillis.</p>
            <p>Toutes les connexions vers et depuis notre site Web sont cryptées à l’aide de la technologie Secure Socket Layer (SSL). De plus, vos données génétiques brutes sont complètement dépersonnalisées et cryptées dans notre base de données cryptée pour protéger les informations sensibles en ligne, nous faisons également tout ce qui est en notre pouvoir pour protéger les informations des utilisateurs hors ligne.</p>
            <p>En plus de protéger les informations des utilisateurs contre les accès non autorisés de personnes extérieures à l’entreprise, nous limitons strictement l’accès à cette base de données depuis l’intérieur de l’entreprise. Seuls les employés qui ont besoin des informations pour effectuer un travail spécifique (par exemple, un représentant du service clientèle) ont accès aux informations personnellement identifiables.</p>
          `,
      },
      {
        question: "PureInsight<sup>🅪</sup> est-il sécurisé et privé?",
        answer: `
            <p>
              Nous stockons vos résultats de tests génétiques sans votre nom ou d’autres informations d’identification communes. Cela signifie qu’aucun affilié extérieur ne peut accéder à vos données génétiques et que vos informations personnelles ont été détachées de votre ADN.
            </p>
            <p>
              Nous utilisons un logiciel de serveur sécurisé pour chiffrer les informations personnelles (y compris les informations de santé sensibles et les données génétiques), et nous ne travaillons qu’en partenariat avec des entreprises de sécurité qui respectent et s’engagent à respecter nos normes de sécurité. Bien que nous ne puissions pas garantir que la perte, l’utilisation abusive ou l’altération des données ne se produira pas, nous faisons des efforts raisonnables pour empêcher cela.
            </p>
            <p>
              Il est également important pour vous de vous prémunir contre l’accès non autorisé à vos renseignements personnels en conservant des mots de passe forts et en vous protégeant contre l’utilisation non autorisée de votre propre ordinateur ou appareil. Vous ne devez pas divulguer vos informations d’authentification à un tiers et devez nous informer immédiatement de toute utilisation non autorisée de votre mot de passe.
            </p>
          `,
      },
      {
        question: "Qui aura accès à mon ADN?",
        answer: `
            <p>
              Seul vous et votre professionnel de santé aurez accès à votre fichier de données
              génétiques. Nous ne partageons pas avec des tiers votre nom ou d’autres informations
              d’identification communes liées à vos données génétiques, sauf si la loi l’exige ou avec votre
              consentement explicite. Nous ne pouvons pas sécuriser les données personnelles que vous
              nous communiquez ou que vous nous demandez de communiquer. Si vous demandez à votre
              fournisseur de soins de santé d’incorporer vos données dans votre dossier médical, par
              exemple, en vertu de la réglementation fédérale, le gouvernement peut y accéder à des fins
              d’application de la loi.
            </p>
            <p>
              Veuillez noter que si vous accédez au site Web de Nordic Labs, 
              23andMe® ou d’Ancestry® par le biais de liens fournis sur ce site Web, 
              vous quittez PureInsight<sup>🅪</sup> et serez soumis aux politiques de confidentialité et de sécurité de Nordic Labs, 
              23andMe<sup>®</sup> ou d’Ancestry<sup>®</sup>, selon le cas. Nous ne sommes pas responsables du contenu ou des services 
              fournis par des sites Web externes.
            </p>
          `,
      },
      {
        question: "Qui puis-je contacter pour obtenir de l’aide?",
        answer: `
            <p>
              Pour toute question, notre équipe de service Client est disponible par 
              email : <a href="mailto:pureinsightpro@ca.nestle.ca">pureinsightpro@ca.nestle.ca</a> 
            </p>
          `,
      },
    ],
  },
];
