import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';

export default function InfoBox({
	onCloseHandler,
	content,
	loading,
	hideFirstInfo,
	hideSecondInfo,
}) {
	const { t, i18n } = useTranslation();

	const [mainContent, setMainContent] = useState({
		top_1: {
			title: "Getting title...",
			content: "Getting content...",
			links: [
				{
					link: "/",
					link_text: "Getting link....",
				},
			],
		},
		top_2: {
			title: "Getting title...",
			content: "Getting content...",
			links: [
				{
					link: "/",
					link_text: "Getting link...",
				},
			],
		},
		top_3: {
			title: "Getting title...",
			content: "Getting content...",
		}
	});

	useEffect(() => {
		if (!loading) setMainContent(content);
	}, [loading]);

	return (
		<div>
			{loading ? (
				"Loading"
			) : (
				<div className="pg-card" style={{ backgroundColor: "#F6F6F6" }}>
					<div className="p-4 px-5">
						<div className="">
							<div className="pg-heading text-center mb-2" dangerouslySetInnerHTML={{ __html: t('pracd_whatsnew') }} />
						</div>
						{!_.isEmpty(mainContent.top_3) && !hideFirstInfo && (
							<div className="col-12 ">
								<div className="text-dark pg-heading__sub my-2">
									{mainContent.top_3.title}
								</div>
								<div className="pg-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mainContent.top_3.content) }} />
								{mainContent.top_3.links?.map((linkData, i) => {
									return (
										// <a href={linkData.link} className="pg-text" key={i}>
										// 	{linkData.link_text}
										// </a>
										<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(linkData.link_text) }} className="pg-text my-2 d-block" />
									);
								})}

								{/* <a
									className="cursor-pointer pg-text d-block my-3"
									onClick={() => onCloseHandler("1")}
									id="close-infobox__dashboard"
								>
									Dismiss
								</a> */}
							</div>
						)}
						{!_.isEmpty(mainContent.top_1) && !hideSecondInfo && (
							<div className="mt-4">
								<div className="text-dark pg-heading__sub">
									{mainContent.top_1?.title}
								</div>
								<div className="pg-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mainContent.top_1.content) }} />
								{mainContent.top_1?.links?.map((linkData, i) => {
									return (
										// <a
										// 	href={linkData.link}
										// 	className="pg-text my-2 d-block"
										// 	key={i}
										// >
										// 	{linkData.link_text}
										// </a>
										<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(linkData.link_text) }} className="pg-text my-2 d-block" />
									);
								})}
								{/* <a
									className="cursor-pointer pg-text d-block my-3"
									onClick={() => onCloseHandler("2")}
									id="close-infobox__dashboard"
								>
									Dismiss
								</a> */}
							</div>
						)}
						{!_.isEmpty(mainContent.top_2) && !hideSecondInfo && (
							<div className="mt-4">
								<div className="text-dark pg-heading__sub">
									{mainContent.top_2?.title}
								</div>
								<div className="pg-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mainContent.top_2.content) }} />
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
