import DOMPurify from 'dompurify';
export const PgRadioButton = ({ checked, onCheck, title, name, value, id, isDisable }) => {
  return (
    <label className="pg_radio my-3  mx-3 pg-text" id={`${id}__label`}>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={onCheck}
        disabled={isDisable || false}
      />
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
      <div className="pg_radio__btn" id={`${id}__cb`}></div>
    </label>
  );
};
