import { useEffect, useState } from "react";
import { ColorsCircle } from "../ColorsCircle";
import {
  motion,
  animate,
  Reorder,
  useMotionValue,
  useDragControls,
} from "framer-motion";
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';

export const RecommendationItems = ({
  index = 0,
  item,
  focusAreas,
  data,
  isEditing,
  selectedItem,
  onSelect,
  onDragEnd,
  onReorder,
  recommendations,
}) => {
  const { t } = useTranslation();
  const dragControls = useDragControls();
  const onSelectionChange = (id) => {
    console.log({ id });
    if (selectedItem.includes(id)) {
      console.log({ id }, "is here");
      onSelect(selectedItem.filter((item) => item !== id));
    } else {
      onSelect((os) => {
        return [...os, id];
      });
    }
  };

  // keyboard events
  const handleKeyDown = (e) => {
    // const index = recommendations.findIndex(rec => rec.recommendation_id === item.recommendation_id);
    if (e.key === "ArrowUp" && index > 0 && isEditing) {
      // Move item up
      const newOrder = [...recommendations];
      [newOrder[index - 1], newOrder[index]] = [
        newOrder[index],
        newOrder[index - 1],
      ];
      onReorder(newOrder);
    } else if (
      isEditing &&
      e.key === "ArrowDown" &&
      index < recommendations.length - 1
    ) {
      // Move item down
      const newOrder = [...recommendations];
      [newOrder[index + 1], newOrder[index]] = [
        newOrder[index],
        newOrder[index + 1],
      ];
      onReorder(newOrder);
    } else if (isEditing && e.key === " ") {
      e.preventDefault();
      onSelectionChange(data.recommendation_id);
    }
  };

  return (
    <Reorder.Item
      onDragEnd={onDragEnd}
      as="li"
      value={item}
      id={item.recommendation_id}
      dragListener={false}
      dragControls={dragControls}
      className="d-flex justify-content-between align-items-center rounded"
      role="listitem" // Add role for screen reader accessibility
      aria-label={`Diet Recommendation: ${item.recommendation}`} // Add ARIA label for description
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {/* <label
                htmlFor={`checkbox-${data.recommendation_id}`}
                className="w-100 d-flex justify-content-between align-items-center"
            > */}
      {isEditing && (
        <motion.div
          className="mx-1"
          initial={{ scaleY: 0 }}
          animate={{ scaleY: isEditing ? 1 : 0 }}
          transition={{ duration: 0.1, type: "easeIn", delay: 0.2 }}
        >
          <input
            type="checkbox"
            tabIndex={-1}
            style={{ height: 20, width: 20 }}
            id={`checkbox-${data.recommendation_id}`}
            checked={selectedItem.includes(data.recommendation_id)}
            onChange={() => onSelectionChange(data.recommendation_id)}
            aria-checked={
              selectedItem.includes(data.recommendation_id) ? "true" : "false"
            }
            className="pg-checkbox"
          />
          {/* <label htmlFor={`checkbox-${data.recommendation_id}`}> // Add label for checkbox
                    Select Recommendation
                </label> */}
        </motion.div>
      )}
      <div className="rounded p-1 p-lg-2 bg_tertiary my-2 user-select-none d-flex justify-content-between align-items-center w-100">
        <div>
          <div>
            <ColorsCircle
              focusAreas={focusAreas}
              data={data.associated_focus_areas_key}
            />
          </div>
          <span
            className="user-select-none"
            style={{ overflowWrap: "anywhere" }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.recommendation) }}
          />
        </div>
        {isEditing && (
          <div
            className="px-2 cursor-grab"
            onPointerDown={(event) => dragControls.start(event)}
            role="button"
            onClick={(e) => e.preventDefault()}
            aria-label={t("drag_to_reorder")}
          >
            <i className="fa fa-grip-lines  text_primary"></i>
          </div>
        )}
      </div>
      {/* </label> */}
    </Reorder.Item>
  );
};

const inactiveShadow = "0px 0px 0px rgba(0,0,0,0.8)";

export function useRaisedShadow(value) {
  const boxShadow = useMotionValue(inactiveShadow);

  useEffect(() => {
    let isActive = false;
    value.onChange((latest) => {
      const wasActive = isActive;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(boxShadow, "5px 5px 10px rgba(0,0,0,0.3)");
        }
      } else {
        isActive = false;
        if (isActive !== wasActive) {
          animate(boxShadow, inactiveShadow);
        }
      }
    });
  }, [value, boxShadow]);

  return boxShadow;
}
