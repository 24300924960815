import React, { useState } from "react";
import { getLifeStyleSuggestionDetails } from "../../../store/actions/patientReport";
import LifestyleItemDetails from "./LifeStyleItemDetails";
import down from "../../../assets/down.png";
import right from "../../../assets/right.png";
import DOMPurify from 'dompurify';

export default function LifestyleItem({
  title,
  lifestyleKey,
  description,
  userType,
}) {
  const patientTextColor = {
    color:
      userType === "Patient" ? "var(--pg_primary__p)" : "var(--pg_primary)",
  };
  const [showDetails, setShowDetails] = useState(false);
  return (
    <div>
      <div className="container-fluid">
        <div
          className="row pt-4 pb-1 cursor-pointer px-0"
          style={{ borderTop: "0.4px solid grey" }}
          onClick={() => setShowDetails(!showDetails)}
        >
          <div
            className="col-lg-4 col-11 mb-3 pg-heading"
            style={patientTextColor}
          >
            {title}
          </div>
          <div className="col-lg-1 col-1 mb-3 pg-text order-lg-last text-end">
            {showDetails ? (
              <img
                src={down}
                style={{ cursor: "pointer" }}
                onClick={() => setShowDetails(!showDetails)}
              />
            ) : (
              <img
                src={right}
                style={{ cursor: "pointer" }}
                onClick={() => setShowDetails(!showDetails)}
              />
            )}
          </div>
          <div className="col-lg-7 col-12 mb-3">
            {!showDetails && (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(description?.substring(0, 180) + "..."),
                }}
              />
            )}
          </div>
        </div>
        {showDetails && <LifestyleItemDetails lifestyleKey={lifestyleKey} />}
      </div>
    </div>
  );
}
